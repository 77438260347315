var ReservationSearchService = (function() {
    var _manager_base_url;
    var _completion_callback;
    var _actuals;
    var _cursor;

    var _start_date;
    var _end_date;
    var _shift_code;
    var _bookedby_id;
    var _order_index;

    var _searchHash;

    var initialize = function(
      manager_base_url,
      start_date,
      end_date,
      shift_code,
      bookedby_id,
      order_index,
      completion_callback) {
        _reset();
        _manager_base_url = manager_base_url;
        _start_date = start_date;
        _end_date = end_date;
        _shift_code = shift_code;
        _bookedby_id = bookedby_id;
        _order_index = order_index;
        _completion_callback = completion_callback;
    };

    var clear = function() {
      console.log('ReservationSearchService.clear');
      _reset();
    };

    var isLoading = function() {
      console.log('ReservationSearchService.isLoading');
      return _lazyLock;
    };

    var _reset = function() {
      _actuals = [];
      _cursor = undefined;

      _start_date = undefined;
      _end_date = undefined;
      _shift_code = undefined;
      _bookedby_id = undefined;
      _order_index = undefined;
      _searchHash = undefined;

      _completion_callback = undefined;
    };

    var resetCursor = function() {
      _cursor = undefined;
      _actuals = [];
    };

    var _getHash = function (query) {
      return '' + query + Math.random();
    };

    var searchMore = function(query) {
      console.log('ReservationSearchService.searchMore');
      _searchHash = _getHash(query);

      var url = _manager_base_url + '/search/allreservations';
      url += '?cursor='
      if (_cursor) {
        url += _cursor;
      }
      if (query) {
        url += '&q=' + query;
      }
      if (_start_date) {
        url += '&start_date=' + _start_date;
        url += '&start_date_localized=' + _start_date;
      }
      if (_end_date) {
        url += '&end_date=' + _end_date;
        url += '&end_date_localized=' + _end_date;
      }
      if (_bookedby_id) {
        url += '&bookedby_id=' + _bookedby_id;
      }
      if (_shift_code) {
          url += '&shift_code=' + _shift_code;
      }
      if (_order_index) {
        url += '&order_index=' + _order_index;
      }

      url += '&searchHash=' + _searchHash;

      Pmp.Client.AsyncGet(url, function(data) {
        if (_searchHash != data.payload.searchHash) {
          return;
        }
        _cursor = data.payload.cursor;
        _actuals = _actuals.concat(data.payload.actuals);
        _completion_callback(_actuals);
      });
      return true;
    };

    var search = function(query) {
      resetCursor();
      return searchMore(query);
    };

    // Export public API
    return {
        initialize: initialize,
        clear: clear,
        isLoading: isLoading,
        search: search,
        searchMore: searchMore,
        resetCursor: resetCursor
    };
})();
