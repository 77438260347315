// This file was automatically generated from concierge.account.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }


Nightloop.Templates.Concierge.Account = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Concierge.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Concierge.AccountBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.AccountBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<!-- ', opt_data.content, ' --><!-- ', soy.$$escapeHtml(opt_data.MEDIA_URL), ' --><div id="page-account" class=""><div id="main-area"><div id="common-account-form">');
  Nightloop.Base.AccountForm(soy.$$augmentData(opt_data, {formaction: '/account-edit', base_url: opt_data.concierge.base_url}), output);
  output.append('<span class="clearfix"></span></div></div></div>');
  return opt_sb ? '' : output.toString();
};
