var JQueryAssignmentsAndProblemsService = (function () {

  var _getAssignmentsAndProblems = function (venue_url_key, date_url, shift_persistent_id, show_problem_res,
                                             show_auto_assignments, force_update_actual_id) {
    var deferred = new $.Deferred();
    $.ajax({
      url: '/api-yoa/availability/auto_assignments',
      method: 'GET',
      data: {
        venue: venue_url_key,
        date: date_url,
        shift_persistent_id: shift_persistent_id,
        show_problem_res: show_problem_res,
        show_auto_assignments: show_auto_assignments,
        force_update_actual_id: force_update_actual_id
      },
      success: function (resp) {
        deferred.resolve(resp.data);
      },
      error: function (resp) {
        var error_json = $.parseJSON(resp.responseText);
        deferred.reject(error_json.message);
      }
    });
    return deferred;
  };

  return {
    getAssignmentsAndProblems: _getAssignmentsAndProblems
  };

})();
