var Pmp = Pmp || {};
Pmp.Admin = Pmp.Admin || {};

Pmp.Admin.VenueGroupsService = {
  url: '/admin/venuegroup/list_api',
  entity_label: 'Venue Groups',
  label_field: 'name',
  venue_groups: null,
  fetch: function (callback) {
    if (this.venue_groups) {
      setTimeout(function () {
        callback(this.venue_groups);
      }.bind(this), 0);
      return;
    }
    Pmp.Client.LoadAjaxData(this.url, null, false, function (data) {
      if (!this._is_valid_response(data)) {
        callback(null);
        return;
      }
      this.venue_groups = data.payload.content.entities;
      callback(this.venue_groups);
    }.bind(this));
  },
  _is_valid_response: function (data) {
    return !!(data && data.payload && data.payload.content && data.payload.content.entities);
  },
};
