
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};

Pmp.Concierge.Landing = {
	initialize : function(base_url,locale,muni_today_date, muni_today_strict_date) {
		this._base_url = base_url;
		this._locale = locale;
		this._muni_today_date = muni_today_date;
		this._muni_today_strict_date = muni_today_strict_date;
		this.titles = [];
		this.subtitles = [];
		this.textColor = []
		this.images = [];
		this.links = [];
		this._current_title = 1;
		this._bindEventHandlers();
		$('#dining-tab').click();
	},

	log : function(msg) {
		console.log("Pmp.Concierge.Landing: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_setTonightDate : function(is_strict) {
		if ($('#search-date-display').html() == 'Tonight') {
			if (is_strict) {
				this.debug('_setTonightDate - strict');
				$('#search-date-input').datepicker('setDate', this._muni_today_strict_date);
			} else {
				this.debug('_setTonightDate - lenient');
				$('#search-date-input').datepicker('setDate', this._muni_today_date);
			}
		}
	},

	_bindEventHandlers: function() {
		this.debug('_bindEventHandlers');
		var that = this;
		var $dateInput = $('#search-date-input');
		var $dateLabel = $('#search-date-display');
		var minDate = $dateInput.data('min_date');
		var maxDate = $dateInput.data('max_date');

		$('#nightlife-tab').click(function() {
			$(this).addClass('selected');
			$('#dining-tab').removeClass('selected');
			$('#timeslot-input').addClass('no-display');
			that._setTonightDate(false);
		});

		$('#dining-tab').click(function() {
			$(this).addClass('selected');
			$('#nightlife-tab').removeClass('selected');
			$('#timeslot-input').removeClass('no-display');
			that._setTonightDate(true);
		});

		$('#search-submit').click(function() {
			that._submitSearch();
		});

		$('#search-query-input').on('keyup', function(event) {
			if (event.which === 13) {
				that._submitSearch();
				return false;
			}
		});

		Pmp.Utils.LocalizeDatePicker(
			that._locale,
			'#search-date-input',
			'#search-date-input-submit'
		);

		if (minDate !== 'None') {
			$dateInput.datepicker('option', 'minDate', minDate);

			$dateLabel.sext(
				$.datepicker.formatDate(
					Pmp.Utils.dateFormatMedium(this._locale),
					$dateInput.datepicker('getDate')
				)
			);
		} else {
			$dateInput.datepicker('option', 'minDate', that._muni_today_date);
		}

		if (maxDate !== 'None') {
			$dateInput.datepicker('option', 'maxDate', maxDate);
		}

		$('#ui-datepicker-div').addClass('calendar'); /* Theming */

		var clickDateDropdownFn = function(event) {
			that._clickDateDropdown(event);
		};
		var changeDateFn = function(event) {
			that._changeDate(event);
		};

		/* action handlers */
		$('#search-date-select').click(clickDateDropdownFn);
		$('#search-date-input').change(changeDateFn);

		var changePartySize = function(event) {
			that._changePartySize(event);
		};
		$('#home-partysize-select').change(changePartySize);
		this._changePartySize(null);

		$('.arrow').click(function() {
			that.advancePhotos();
		});
	},

  _submitSearch: function() {
		var params = $('#search-form').serialize();
		if ($('#nightlife-tab').hasClass('selected')) {
			Pmp.Client.Static.Page.Navigate('#nightloop', this._base_url+'/nightlife/search?' + params);
		} else {
			Pmp.Client.Static.Page.Navigate('#nightloop', this._base_url+'/dining/search/?' + params);
		}
  },

	_changePartySize : function(event) {
		this.debug("_changePartySize");
	},

	_clickDateDropdown : function(event) {
		if ($('#ui-datepicker-div').is(':visible')) {
			$('#search-date-input').datepicker('hide');
		} else {
			$('#search-date-input').datepicker('show');
		}
	},

	_changeDate : function(event) {
		var datestr = $.datepicker.formatDate(
			Pmp.Utils.dateFormatMedium(this._locale),
			$('#search-date-input').datepicker('getDate')
		);
		$('#search-date-display').sext(datestr);
	},

	followLink : function() {
		Pmp.Client.Static.Page.Navigate('#nightloop',this.links[this._current_title]);
	},

	advancePhotos : function() {
		if ($(':animated').length > 0) {
			return;
		}

		var ep = ["-=700","-=887","-=647", "-=647"];
		var self = this;
		var onDone = function(el,self) {
			if ($(el).hasClass('photo-far-right')) {
				var photo_left_img = $('.photo-left img').attr('src');

				var photo_left = $('.photo-left');
				var photo_selected =$('.photo-selected');
				var photo_right = $('.photo-right');
				var photo_far_right =$('.photo-far-right');

				photo_selected.removeClass('photo-selected').addClass('photo-left');
				photo_right.removeClass('photo-right').addClass('photo-selected');
				photo_far_right.removeClass('photo-far-right').addClass('photo-right');

				var image_idx = self._current_title;
				self._current_title += 1;
				if (self._current_title == 3) {
					self._current_title = 0;
				}

				var new_image = self.images[image_idx];
				var html = "<div class='photo-far-right photo float-left' onclick=\"Pmp.Client.Static.Page.Navigate('#nightloop',Pmp.Concierge.Landing.links[" + image_idx + "])\"><img class='photo-img' src='" + new_image + "' /></div>";
				$(html).insertAfter(photo_far_right);
				photo_left.remove();

				// change text
				$('.photo-title-container .photo-title').css("color", self.textColor[self._current_title])
				$('.photo-title-container .photo-subtitle').css("color", self.textColor[self._current_title])
				$('.photo-title-container .photo-title').sext(self.titles[self._current_title]);
				$('.photo-title-container .photo-subtitle').sext(self.subtitles[self._current_title]);

        if (!self.subtitles[self._current_title].length) {
          $('.photo-title-container .separator').hide()
        } else {
          $('.photo-title-container .separator').show()
        }

				$('.photo-title-container').fadeIn(100);
				$('.photo-book-now').fadeIn(100);

			};
		};

		$('.photo-title-container').fadeOut(100,function() {
		  $('.photo-book-now').fadeOut(100);
			$('.photo').each(function(idx, el) {
				var func = function(x, ele, self) {
					return function() {
						$(ele).animate({
							left:ep[x]
						}, 800, "swing", function() { onDone(ele, self); } );
					}
				}
				func(idx,el,self)();
			});
		});
	}

};
