// This file was automatically generated from concierge.manage.conciergepromoters.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Manage == 'undefined') { Nightloop.Templates.Concierge.Manage = {}; }


Nightloop.Templates.Concierge.Manage.ConciergePromotersEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Concierge.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Concierge.Manage.ConciergePromotersEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Manage.ConciergePromotersEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-conciergepromoters" class="page-edit-mode"><div><div class="content-header float-left"><span class="header-text">Edit Booked By Names</span>  <a id="contextual-link-bookedby" class="contextual-link" href="javascript:void(0);">(?)</a><div id="contextual-content-bookedby" class="contextual-content">These names appear in the \'Booked by\' drop-down when concierge staff book new reservations.</div></div><div class="float-end"></div></div><div id="main-area" class="float-left w50">', (opt_data.content.error) ? '<div class="white-box error-message">' + soy.$$escapeHtml(opt_data.content.error) + '</div>' : '', '<div class="body-block white-box"><form id=\'conciergepromoters-edit-form\' class=\'ajaxify\' method=\'post\' action=\'', soy.$$escapeHtml(opt_data.concierge.base_url), '/manage/bookedbynames/edit\'><div class="white-box-block"><div class="block-header"><div class="header-text float-left">Names</div><div class="float-end"></div></div></div><div class="white-box-block">');
  var concierge_promoterList12077 = opt_data.content.concierge_promoters;
  var concierge_promoterListLen12077 = concierge_promoterList12077.length;
  for (var concierge_promoterIndex12077 = 0; concierge_promoterIndex12077 < concierge_promoterListLen12077; concierge_promoterIndex12077++) {
    var concierge_promoterData12077 = concierge_promoterList12077[concierge_promoterIndex12077];
    output.append('<div class="concierge-promoter-name"><div class="float-left input-container" id="promoter-name-container-', soy.$$escapeHtml(concierge_promoterIndex12077), '"><div class="promoter-name-prompt" id="promoter-name-prompt-', soy.$$escapeHtml(concierge_promoterIndex12077), '">type new name here</div><input class="promoter-name-input" type="text" name="', soy.$$escapeHtml(concierge_promoterData12077.id), '" value="', soy.$$escapeHtml(concierge_promoterData12077.name), '" maxlength="70" /></div><div class="float-right"><a class="concierge-promoter-delete-link" href="javascript:void(0);">[delete]</a></div><div class="float-end"></div></div>');
  }
  output.append('<div id="add-new-promoters-here" class="no-display"></div></div><div class="white-box-block">');
  Nightloop.Templates.Widget.GoldButton({text: '+ ADD NEW', size: 'small', id: 'add-new-promoter-name'}, output);
  output.append('<div class="float-end"></div></div><div class="white-box-block"><div id="validation-error-message" class="float-left no-display">Provide a name for every row</div><div class="float-right"><div class="link-next-to-button-div float-left"><a id="conciergepromoters-cancel-btn" class="ajaxify" href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/manage/bookedbynames">cancel</a></div> <a class="float-left" id="conciergepromoters-submit-btn" href="javascript:void(0);">');
  Nightloop.Templates.Widget.GoldButton({text: 'SAVE', size: 'medium'}, output);
  output.append('</a></div><div class="float-end"></div></div></form></div></div><div class="float-end"></div></div><div class="no-display" id="concierge-promoter-name-clone"><div class="concierge-promoter-name"><div class="float-left input-container" ><div class="promoter-name-prompt">type new name here</div><input class="promoter-name-input" type="text" name="new_concierge_promoter_name" value="" maxlength="70" /></div><div class="float-right"><a class="concierge-promoter-delete-link" href="javascript:void(0);">[delete]</a></div><div class="float-end"></div></div></div><script type="text/javascript">$(document).ready( function() {Pmp.Concierge.Manage.ConciergePromotersEdit.initialize(', soy.$$escapeHtml(opt_data.content.concierge_promoters.length), ');})<\/script>');
  return opt_sb ? '' : output.toString();
};
