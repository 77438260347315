
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Clients = Pmp.Concierge.Clients || {};
Pmp.Concierge.Clients.Reservation = Pmp.Concierge.Clients.Reservation || {};

Pmp.Concierge.Clients.Reservation.Edit = {
	initialize : function() {
		this._landingPage = Pmp.Concierge.Clients.Landing;
		this._initValidation();
		this._bindEventHandlers();
	},
	
	log : function(msg) {
		console.log("Pmp.Concierge.Clients.Reservation.Edit: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_initValidation : function() {
		var self = this; 
		var activeValidatorsList = ['firstName', 'lastName', 'email', 'phoneOrEmpty'/*, 'bbmPin'*/];
		var afterValidationHookFn = $.colorbox.resize;
		var onSubmitHookFn = function(event) {return self._submitEditClientInfo(event);}; 
		
		Pmp.Common.Reservation.Validator.initialize(
				activeValidatorsList,
				afterValidationHookFn,
				onSubmitHookFn
			);
	},
	
	_bindEventHandlers : function() {
		this.debug("bindClickHandlers");
		var self = this;
		var clickCancelBtnFn = function(event) {self._clickCancelBtn(event);};
		var changeBookedByDropdownFn = function(event) {self._changeBookedByDropdown(event);};

		$('#edit-client-info-cancel-btn').click(clickCancelBtnFn);
		$('#booked-by').change(changeBookedByDropdownFn);
		Pmp.Utils.InputOverlayPrompt('#new-promoter-name-container', '#new-promoter-name-prompt');
	},
	
	_submitEditClientInfo : function(event) {
		this._landingPage.changedReservation();
		var formObj = $('#edit-client-info-form');
		var onLoadFn = function() {
			// Make sure all img tags are loaded before resizing to avoid scrollbar appearing
			$('#clients-landing-popup').find('img').batchImageLoad({
				loadingCompleteCallback: $.colorbox.resize
			});
		};
		Pmp.Utils.LoadForm(event, formObj, '#clients-landing-popup', onLoadFn);
		return false; /* Should not auto-submit form */
	},
	
	_clickCancelBtn : function(event) {
		var anchor = Pmp.Utils.GetParentElemForEventHandle(event, '#edit-client-info-cancel-btn');
		this._landingPage.loadClientResAction(anchor, 'view', true);
	},
	
	_show_new_promoter_name : function() {
		$('#new-promoter-name-container').show();
		$.colorbox.resize();
	},
	
	_hide_new_promoter_name : function() {
		$('#new-promoter-name-container').hide();
		$.colorbox.resize();
	},
	
	_changeBookedByDropdown : function(event) {
		var booked_by_val = $('#booked-by').val();
		if (booked_by_val == '--self--') {
			this._hide_new_promoter_name();
		} else if (booked_by_val == '--new--') {
			this._show_new_promoter_name();
		} else {
			this._hide_new_promoter_name();
		}
	}
};