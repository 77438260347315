var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Manage = Pmp.Concierge.Manage || {};

Pmp.Concierge.Manage.FeaturedVenues = {
    initialize : function() {
        this.bindEventHandlers();
    },

    bindEventHandlers: function() {
        var self = this;
        $('.featured-venue-type').change(function() {
            var $this = $(this);
            var parent = $this.parent();
            var $parent = $(parent);
            if ($this.val() === 'default') {
                $parent.next('.featured-venue-props').addClass('no-display');
            } else {
                $parent.next('.featured-venue-props').removeClass('no-display');
            }
        });
        $('.featured-venue-id').change(function() {
            var $this = $(this);
            var venueName = $("option:selected", this).text();
            var venuePosition = $this.attr('name').split('-')[0];
            var venueNameSelector = ['input[name="', venuePosition, '-featured-venue-name"]'].join('');
            $(venueNameSelector).val(venueName);
        });
        // Turns out this wasn't needed but potentially one day
//        var postFeaturedVenuePhotoUploadHandler = function(e, data) {
//            var photoUrl = '/.h/download/' + data.photo_key;
//            var photoPosition = data.position;
//            $('#' + photoPosition + '-photo-preview').removeClass("no-display");
//            $('#' + photoPosition + '-photo').attr("src", photoUrl);
//        }
//        Uploader.uploadPhotoAndReturnData('#center-featured-venue-photo', postFeaturedVenuePhotoUploadHandler);
//        Uploader.uploadPhotoAndReturnData('#left-featured-venue-photo', postFeaturedVenuePhotoUploadHandler);
//        Uploader.uploadPhotoAndReturnData('#right-featured-venue-photo', postFeaturedVenuePhotoUploadHandler);
    },
}