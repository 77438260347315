
var ShiftService = (function() {

  var _init = function() {
  };
  
  var _getShifts = function(venue, date)
  {
    var date_url = $.datepicker.formatDate('mm/dd/yy', date);
    var deferred = new $.Deferred();
    $.ajax({
      url: '/api-yoa/shifts/schedule',
      data: {
        start_date: date_url,
        end_date: date_url,
        venue: venue
      },
      success: function(resp) {
        deferred.resolve(resp.data.shifts[date_url]);
      }
    });
    return deferred;
  };
  
  var _getArrivalOptions = function(venue, date)
  {
    var get_shifts = _getShifts(venue, date);
    var deferred = new $.Deferred();
    $.when(get_shifts).done(function(shifts) {
      var options = [];
      for (var i = 0; i < shifts.length; ++i) {
        var shift = shifts[i];
        var step = shift.interval_minutes / 15;
        for (var j = shift.start_time_sort_order; j <= shift.end_time_sort_order; j += step) {
          options.push(timeutils.sort_order_to_time(j));
        }
      }
      deferred.resolve(options);
    });
    return deferred;
  };

  return {
    init: _init,
    getShifts: _getShifts,
    getArrivalOptions: _getArrivalOptions
  };

})();
