var MailService = (function() {
  
  var base_url = '';

  var _init = function() {
    base_url = $('#base_url').val();
  };

  /*
   * args contains an email address and an actual_id
   * as well as either a charge_id or straight 
   * charge information. Race conditions and 
   * charge retrieval makes relying on charge_id
   * unreliable.
   */
  var _paymentNotification = function(args) {
    var deferred = $.Deferred(); 

    $.ajax({
      url: base_url + '/billing/notify',
      method: 'post',
      data: args,
      success: function(resp) {
        if( resp.payload.message == 'ERROR' ) {
          deferred.reject(resp);
          return;
        }
        deferred.resolve(resp);
      }
    });

    return deferred;
  };

  return {
    init: _init,
    paymentNotification: _paymentNotification
  };

})();

