var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Directory = Pmp.Concierge.Directory || {};

Pmp.Concierge.Directory.Search = {
    initialize : function(base_url, concierge_id, num_results, locale, venue_class, media_url, muni_today_date, date_formatted_default) {
        this._base_url = base_url;
        this._concierge_id = concierge_id;
        this._num_results = num_results;
        this._venue_class = venue_class;
        this._locale = locale;
        this._media_url = media_url;
        this._date_formatted_default = date_formatted_default;
        this._muni_today_date = muni_today_date;
        this.bindEventHandlers();

        Pmp.Concierge.Request.initialize(this._base_url, muni_today_date, locale, venue_class);
        Pmp.Concierge.Common.SearchPage.initCommonFilterDropdowns(this, $(document));

        this._venue_type_filters = {};
        this._neighborhood_filters = {};
        this._category_filters = {};
        this._atmosphere_filters = {};
        this._cuisine_filters = {};
        Pmp.Concierge.Common.SearchPage.initializeTagFilters(this);
        this.initFilters();
        $('#search-query-input').placeholderLabel();
    },

    debug : function(msg) {
        if (Pmp.Settings.DEBUG) {
            Pmp.Concierge.Common.Utils.debug("Pmp.Concierge.Directory.Search", msg);
        }
    },

    initFilters : function () {
        Pmp.Concierge.Common.SearchPage.initSingleFilter('#filter-neighborhood', 5);
        Pmp.Concierge.Common.SearchPage.initSingleFilter('#filter-cuisine', 5);
        Pmp.Concierge.Common.SearchPage.initSingleFilter('#filter-atmosphere', 7);
        Pmp.Concierge.Common.SearchPage.initSingleFilter('#filter-category', 5);
    },

    bindEventHandlers : function() {
        Pmp.Concierge.Common.SearchPage.bindCommonSearchPageEventHandlers(this);
        Pmp.Concierge.Common.SearchPage.bindSubscriptionEvents(this._concierge_id);
    },
};
