


var SeatingService = {
    
    getByVenue : function(manager_base_url, onSuccess, onError) {
      return $.ajax({
        url: manager_base_url + '/data/seatingareastables',
        success: function(response) {
          if (onSuccess !== undefined) {
            onSuccess(response.payload.content);
          }
        },
        error : onError
      });
    }
}
