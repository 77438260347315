var ConciergeReservationService = (function() {
  var _get = function(
    baseUrl,
    query,
    startDate,
    endDate,
    bookedbyId,
    sortByDatetype,
    conciergeId,
    clientConciergeId,
    status,
    cursor,
    searchHash
  ) {
    var deferred = new $.Deferred();
    $.ajax({
      url: baseUrl + '/clients/reservations',
      method: 'post',
      data: {
        query: query,
        start_date: startDate,
        end_date: endDate,
        booked_by_id: bookedbyId,
        sort_by_datetype: sortByDatetype,
        concierge_id: conciergeId,
        client_concierge_id: clientConciergeId,
        status: status,
        cursor: cursor,
        search_hash: searchHash
      },
      success: function(resp) {
        deferred.resolve(
            resp.payload.reservations,
            resp.payload.next_cursor,
            resp.payload.search_hash
        );
      }
    });
    return deferred;
  }
  return {
    get: _get
  };
})();
