
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};

Pmp.Concierge.Availability = {
	initialize : function(base_url, venue_id, venue_name, is_nightlife_class, muni_today_date, initDateText,
			venueDaysOfOperation, venueCustomOpenedDates, venueBlackedDates, locale,
			venueTimeOptions, populateSearchFields, previousReservationId,
		  actualId, canCustomDiningRequest) {
		this._base_url = base_url;
    this._venue_id = venue_id;
		this._venue_name = venue_name;
		this._is_nightlife_class = is_nightlife_class;
		this._is_dining_class = !is_nightlife_class;
		this._muni_today_date = muni_today_date;
		this._muni_today_date_date = Pmp.Utils.parseDate(this._muni_today_date, locale);
		this._venueDaysOfOperation = venueDaysOfOperation;
		this._venueCustomOpenedDates = venueCustomOpenedDates;
		this._venueBlackedDates = venueBlackedDates;
		this._initDateText = initDateText;
		this._locale = locale;
		this._venueTimeOptions = venueTimeOptions;
		this._is_searching = false;
		this._canCustomDiningRequest = canCustomDiningRequest;
		this._searchHash = 0;

		this._initSearchCalendar();
		this._bindEventHandlers();

		this._initDropdowns();

		if (populateSearchFields) {
			this._initSearchPrompts();
		} else {
			this.loadUpcomingAvailability();
		}
		this._venue_class = (this._is_dining_class) ? 'DINING' : 'NIGHTLIFE';

		this._previousReservationId = previousReservationId;
		this._actualId = actualId;
	},

	log : function(msg) {
		console.log("Pmp.Concierge.VenuePage: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_initSearchCalendar : function() {
		this.debug("_initSearchCalendar");
		var self = this;

    $('.avail-date-calendar').datepicker({
      minDate : this._muni_today_date,
      dateFormat : Pmp.Utils.dateFormat(this._locale),
      defaultDate : (this._initDateText !== undefined) ? this._initDateText : null,
      setDate : (this._initDateText !== undefined) ? this._initDateText : null,
      daysNamesMin : ['S','M','T','W','T','F','S'],
      monthNames : ['JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER'],
      showOtherMonths : true,
      selectOtherMonths : true,
      onSelect : function () {
        self.onSelectDate();
      },
      beforeShowDay : function (date) {
        return self._beforeShowDay(date);
      }
    });
    Pmp.Utils.LocalizeDatePicker(self._locale, '.avail-date-calendar', '.avail-date');
		$('.avail-date-calendar').addClass('calendar');
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
    var onClickDocumentHideCalendar = function(event) {self._hideCalendarOnClick(event); };
		$(document).mouseup(onClickDocumentHideCalendar);
    $('.availselect.date').on('click', function () {
      $('.avail-date-calendar-container').removeClass('no-display');
    });
    $('.availbutton').on('click', function() {
      self.loadAvailability();
    });

		$('.availselect.guests select').on('change', this._updateSelectIndent);
		$('.availselect.time select').on('change', this._updateSelectIndent);
		$('.availselect.guests select').change();
		$('.availselect.time select').change();

		$('#ui-datepicker-div').addClass('calendar'); /* Theminng */
	},

	_initDropdowns : function() {
		var self = this;
    self.onSelectDate();

	},

	_initSearchPrompts : function() {
		this._updateSearchedDisplay();
		this.loadAvailability();
	},

  _hideCalendarOnClick : function(e) {
		var container = $('.avail-date-calendar');
    if (container.has(e.target).length === 0) {
    	$(".avail-date-calendar-container").addClass('no-display');
    }
	},

  onSelectDate : function () {
		this._updateTimeSlots();
    this._changeSearchDate();
    $(".avail-date-calendar-container").addClass('no-display');
  },

	_changeSearchDate : function(skipAnimation) {
		this.debug("_changeSearchDate");
		var dateDisplay = this._getSelectedDateDisplay();
    $('.availselect.date .val').sext(dateDisplay);

		// init defaults
		Pmp.Concierge.Request.overrideDate = $('.avail-date').val();
		Pmp.Concierge.Request.overrideGuests = $('select.guests').val();
	},

	_getSelectedDateDisplay : function() {
		var date = $('.avail-date-calendar').datepicker('getDate');
		if ($.inArray(this._locale, ['en_US', 'en_CA']) > -1) {
			return $.datepicker.formatDate('D, MM d', date);
		}
		return $.datepicker.formatDate('D, d MM', date);
	},

	_beforeShowDay : function(date) {
		var date_formatted = $.datepicker.formatDate('mm/dd/y', date);
		var day_of_week_short = $.datepicker.formatDate('D', date);
		var dow_index = $.inArray(day_of_week_short, ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']);
		var td_class = '';

		/* Trumping order of background colors:
		 *  - Passed Date
		 *  - Today
		 *  - Closed Date (except custom opened ones)
		 *  - Blackout Dates
		 */
		if (date.compareTo(this._muni_today_date_date) < 0) {
			td_class = 'passed-date';
		} else if (date.compareTo(this._muni_today_date_date) == 0) {
			td_class = 'today-date'
		} else if (!this._venueDaysOfOperation[dow_index] && $.inArray(date_formatted, this._venueCustomOpenedDates) < 0) {
			td_class = 'closed-date';
		} else if ($.inArray(date_formatted, this._venueBlackedDates)  >= 0) {
			td_class = 'closed-date';
		}

		return [true, td_class];
	},

	_updateTimeSlots : function () {
		var self = this;
		var date = $('.avail-date').val().replace(/\//g,'-');
    $.ajax({
      url: '/venue/' + this._venue_id + '/time_options/' + date + '?locale=' + this._locale,
      method: 'get',
      success: function(resp) {
        var timeOptions = resp.payload.time_options;
        var arrivalTime = $('.time_slot');
				var selectedTime = $('.time_slot option:selected').val();
        arrivalTime.find('option').remove();
				var defaultOption = $("<option />").text('select');
				arrivalTime.append(defaultOption);
        for (var o=0 ; o<timeOptions.length; o++) {
          var option = $("<option />").val(timeOptions[o]).text(timeOptions[o]);
					if (selectedTime == timeOptions[o]) {
						option.attr('selected','selected');
					}
          arrivalTime.append(option);
        }
				arrivalTime.change();
      }
    });
	},


	_updateSelectIndent : function () {
		var val = $(this).val();
		if ((val == '' || val.toLowerCase().indexOf('m') == -1)
     && this.name == 'time_slot') {
			$(this).css('padding-left', '91px');
		} else {
			$(this).css('padding-left', '81px');
		}

		Pmp.Concierge.Request.overrideGuests = $('select.guests').val();
	},


	loadUpcomingAvailability : function () {
		// look at 4 days, for party size 2 and 4
		var data = {
			2 : [],
			4 : []
		}

		var self = this;
		var today = this._muni_today_date_date;
		var timeDeltas = [0,1,2,3];
		var deferreds = [];

		for (var ps=2; ps <= 4; ps+=2) {
			for (var i=0; i < timeDeltas.length; i++) {
	      var currentDate = new Date();
	      currentDate.setDate(today.getDate() + timeDeltas[i]);
				var dateStr = $.datepicker.formatDate('mm/dd/yy', currentDate);
				var params = {
	        date : dateStr,
					time_slot : '8:00 PM',
	        max_guests : ps,
					expand : 0,
					actual_id : self._actualId
	      }
				if (i==0 && ps==2) {
						params['track_search'] = true;
				}
	      deferreds.push(this._doSearch(params));
	    }
		}

		$.when.apply($, deferreds).done(function() {
			for (var i = 0; i < deferreds.length; i++) {
				deferreds[i].done(function(resp){
					var dateStr = $.datepicker.formatDate(
						Pmp.Utils.dateFormatMediumShortMonth(self._locale),
						Pmp.Utils.parseDate(resp.date_url, 'en_US')
					);
					resp['dateFormatted'] = dateStr;
					data[resp.max_guests].push(resp);
        });
			}
			self._showRightSideAvailability(data);
    });


	},

	_showRightSideAvailability: function(data) {
		var self = this;
		var sel = '.upcoming-avail-container'
		$(sel).empty();

		var hasAvailDct = {}
		for (key in data) {
			var hasAvail = false;
			for (var i=0; i<data[key].length; i++) {
				if (data[key][i].has_availability) {
					hasAvail = true;
				}
			}
			hasAvailDct[key] = hasAvail;
		}

		var html = Nightloop.Templates.Concierge.UpcomingAvailability({
			base_url : self._base_url,
			venue_class : self._venue_class,
			venue_name : self._venue_name,
			venue_id : self._venue_id,
			avail_by_party_size : data,
			has_avail_by_party_size : hasAvailDct,
			can_custom_dining_request : self._canCustomDiningRequest
		});
		$(sel).append(html);
		Pmp.Concierge.Request.bindPopupClick();
		//$.colorbox.resize(); // for popups
	},

	_updateSearchedDisplay: function () {
		var date = $('.avail-date').val();
    var time = $('select.time_slot').val();
    var guests = $('select.guests').val();

		var dateFormatted = Pmp.Utils.parseDate(date, 'en_US');
		dateFormatted = $.datepicker.formatDate('DD, MM d, yy', dateFormatted);
		$('.searched-date').sext(dateFormatted.toUpperCase());
		$('.searched-guests').sext(guests);
		$('.searched-timeslot').sext(time);
	},

  loadAvailability : function () {
    var self = this;
		this._searchHash += 1;
		var currentSearch = this._searchHash;

		// search params
    var date = $('.avail-date').val();
    var time = $('select.time_slot').val();
    var guests = $('select.guests').val();

		// validate
		$('.availselect.time').removeClass('error-outline');
		$('.availselect.guests').removeClass('error-outline');
		var valid = true;
		if (!time.length || time.toLowerCase() == 'select') {
			$('.availselect.time').addClass('error-outline');
			valid = false;
		}
		if (!guests.length) {
			$('.availselect.guests').addClass('error-outline');
			valid = false;
		}
		if (!valid) {
			return;
		}

		// update result
		$('.conciergeavailview .results').removeClass('no-display');
		this._updateSearchedDisplay();
		$.colorbox.resize();

    // make list of valid dates
    var fullDay = 1000*60*60*24;
    var today = this._muni_today_date_date;
    var searchedDate = Pmp.Utils.parseDate(date, 'en_US'); // always in this format
    var todayDelta = Math.floor((searchedDate.getTime() - today.getTime()) / fullDay);
    var timeDeltas = [-3,-2,-1,0,1,2,3];
    var deltaMap = {
      0 : [0,1,2,3,4,5,6],
      1 : [-1,0,1,2,3,4,5],
      2 : [-2,-1,0,1,2,3,4],
      3 : [-3,-2,-1,0,1,2,3]
    };
    if (todayDelta < 4) {
      timeDeltas = deltaMap[todayDelta];
    }

		var deferredList = [];
    var deferreds = {};
		var datePosition = {};
    for (var i=0; i < timeDeltas.length; i++) {
      var currentDate = new Date();
			currentDate.setTime(searchedDate.getTime() + (fullDay * timeDeltas[i]));
			var dateStr = $.datepicker.formatDate('mm/dd/yy', currentDate);
			var params = {
        date : dateStr,
        time_slot : time,
        max_guests : guests,
				actual_id : self._actualId
      }
			if (i==0) {
					params['track_search'] = true;
			}
      deferreds[dateStr] = this._doSearch(params);
			deferredList.push(deferreds[dateStr]);
			datePosition[dateStr] = i;
    }

    // process responses
		self._clearSlots();
    for (var date in deferreds) {
      function finish(d) {
        deferreds[d].done(function(resp){
					if (currentSearch == self._searchHash) {
							self._showAvailabilityResult(d, resp, datePosition, searchedDate);
					}
        });
      }
      finish(date);
    }

  },


	_clearSlots : function () {
		$('.rows .slot').empty();
	},

  _showAvailabilityResult : function(dateStr, resp, datePosition, searchedDate) {
		var self = this;
		var sel = '.s-' + datePosition[dateStr];
		$(sel).empty();
		var searchedDateStr = $.datepicker.formatDate('mm/dd/yy', searchedDate);
		var html = Nightloop.Templates.Concierge.AvailabilityRow({
			is_search : dateStr == searchedDateStr,
			base_url : self._base_url,
			venue_class : self._venue_class,
			venue_id : self._venue_id,
			venue_name : self._venue_name,
			max_guests : resp.max_guests,
			reservation_id : resp.reservation_id,
			date_formatted : resp.date_formatted,
			date_url : resp.date_url,
			time_slots : resp.search_result.timeslots,
			is_empty_code : resp.search_result.is_empty_code,
			previous_reservation_id : self._previousReservationId
		});
		$(sel).append(html);
		$.colorbox.resize(); // for popups
  },

  _doSearch : function (searchDict) {
    var deferred = new $.Deferred();
    var url = this._base_url + '/venue/' + this._venue_id + '/dining_availability';
    $.ajax({
      url: url,
      method: 'GET',
      data: searchDict,
      success: function(resp) {
        deferred.resolve(resp);
      },
      error : function(resp) {
        var error_json = $.parseJSON(resp.responseText);
        deferred.reject(error_json.message);
      }
    });
    return deferred;
  }

};
