
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Manage = Pmp.Concierge.Manage || {};
Pmp.Concierge.Manage.User = Pmp.Concierge.Manage.User || {};

Pmp.Concierge.Manage.User.List = {
	initialize : function() {
		this._bindEventHandlers();
	},
	
	log : function(msg) {
		console.log("Pmp.Concierge.Manage.User.List: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		
		var self = this;
		var clickViewPermissionsLinkFn = function(event) {self._clickViewPermissionsLink(event, $(this));}
		var clickReturnToTopLinkFn = function(event) {self._clickReturnToTopLink(event, $(this));}
		
		/* action handlers */
		$('.view-permissions-link').click(clickViewPermissionsLinkFn);
		$('.return-to-top-link').click(clickReturnToTopLinkFn);
	},
	
	_clickViewPermissionsLink : function(event, element) {
		window.scroll(0,$('.view-permissions-anchor').offset().top);
	},
	
	_clickReturnToTopLink : function(event, element) {
		window.scroll(0,0);
	}
};