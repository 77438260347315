
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Reservation = Pmp.Concierge.Reservation || {};

Pmp.Concierge.Reservation.EnterClientInfo = {
	initialize : function(base_url, date_formatted_default,
												venue_id, STRIPE_KEY, shouldProcessCreditCard, requirePerks) {
		this._base_url = base_url;
		this._date_formatted_default = date_formatted_default;
		this._venue_id = venue_id;
		this._shouldProcessCreditCard = shouldProcessCreditCard;
		this._requirePerks = requirePerks;
		BillingService.init(STRIPE_KEY);
		this._initValidation();
		this._bindEventHandlers();
	},

	log : function(msg) {
		console.log("Pmp.Concierge.Reservation.EnterClientInfo: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_initValidation : function() {
		var self = this;
		var activeValidatorsList = ['firstName', 'lastName', 'email', 'phone','bookedBy'];
		if (self._shouldProcessCreditCard) {
			activeValidatorsList.push('creditCard');
		}
		if (self._requirePerks) {
			activeValidatorsList.push('perkSelected');
		}

		var afterValidationHookFn = function() {
			$('#top-invalid-notice').sext('');
		};
		var onSubmitHookFn = function (event) {

			if (!self._shouldProcessCreditCard) {
				return true;
			}


			var cardData = self._getCardData();
			var onGetCard = BillingService.getToken(
				cardData.name,
				cardData.number,
				cardData.exp_month,
				cardData.exp_year,
				cardData.cvc
			);
			onGetCard.done(function(token) {
				if (!token) {
					$('#top-invalid-notice').sext('Invalid credit card details.')
					return;
				}
				$('.cc-info-area .cc-token').val(token);
        $('.cc-info-area .cc-unencrypted-name').val(cardData.name);
				$('#client-info-submit-btn').closest('form').submit();
			});
			return false;
		};
		Pmp.Common.Reservation.Validator.initialize(
			activeValidatorsList,
			afterValidationHookFn,
			onSubmitHookFn,
			'#book-reservation-form'
		);
	},

	_getCardData : function () {
		var name = $('.cc-info-area .cc-name').val();
		var number = $('.cc-info-area .cc-num').val();
		var exp_month = $('.cc-info-area .cc-exp-m').val();
		var exp_year = $('.cc-info-area .cc-exp-y').val();
		var cvc = $('.cc-info-area .cc-cvc').val();
		return {
			name : name,
			number : number,
			exp_month : exp_month,
			exp_year : exp_year,
			cvc : cvc
		};
	},

	_bindEventHandlers : function() {
		this.debug("bindEventHandlers");
		var self = this;
		var changeBookedByDropdownFn = function(event) {self._changeBookedByDropdown(event);};
		var clickVenuePerksFn = function(event) {self._clickVenuePerks(event, $(this));};

		$('#booked-by').change(changeBookedByDropdownFn);
		$('#see-perk-details-link').click(clickVenuePerksFn);

		Pmp.Utils.InputOverlayPrompt('#new-promoter-name-container', '#new-promoter-name-prompt');

		Pmp.Utils.AddDropdown('#view-deposit-policy', '.deposit-policy-container');
	},

	_clickVenuePerks : function(event, element) {
		var url = this._base_url + '/venue/'+ this._venue_id +'/perks?date=' + this._date_formatted_default;
		Pmp.Main.Popup.Base.loadUrl(url, true);
		Pmp.Main.Popup.Base.showPopup(500);
	},

	_show_new_promoter_name : function() {
		$('#new-promoter-name-container').show();
	},

	_hide_new_promoter_name : function() {
		$('#new-promoter-name-container').hide();
	},

	_changeBookedByDropdown : function(event) {
		var booked_by_val = $('#booked-by').val();
		if (booked_by_val == '--self--') {
			this._hide_new_promoter_name();
		} else if (booked_by_val == '--new--') {
			this._show_new_promoter_name();
		} else {
			this._hide_new_promoter_name();
		}
	}
};
