
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};

Pmp.Concierge.VenuePage = {
	initialize : function(base_url, photo_thumb_urls,
			is_nightlife_class, is_dining_class, reservation_id, muni_today_date, initDateText,
			venueDaysOfOperation, venueCustomOpenedDates, venueBlackedDates, locale, has_results,
			venueTimeOptions, populateSearchFields) {
		
		this._base_url = base_url;
		this._photo_thumb_urls = photo_thumb_urls;
		this._is_nightlife_class = is_nightlife_class;
		this._is_dining_class = is_dining_class;
		this._reservation_id = reservation_id;
		this._muni_today_date = muni_today_date;
		this._muni_today_date_date = Pmp.Utils.parseDate(this._muni_today_date, locale);
		this._venueDaysOfOperation = venueDaysOfOperation;
		this._venueCustomOpenedDates = venueCustomOpenedDates;
		this._venueBlackedDates = venueBlackedDates;
		this._initDateText = initDateText;
		this._locale = locale;
		this._has_results = has_results;
		this._venueTimeOptions = venueTimeOptions;

		this._is_searching = false;

		this._gold_border_animation_duration_ms = 500;
		this._search_prompt_fade_duration_ms = 200;

		this._initSearchCalendar();
		this._initRotator();
		this._bindEventHandlers();
		Pmp.Concierge.Reservation.Custom.initialize(this._base_url, this._muni_today_date, this._locale);
		this._initDropdowns();

		if (populateSearchFields) {
			this._initSearchPrompts();
		}

		var venue_class = (this._is_dining_class) ? 'DINING' : 'NIGHTLIFE';
		Pmp.Concierge.Request.initialize(this._base_url, this._muni_today_date, locale, venue_class);

	},

	log : function(msg) {
		console.log("Pmp.Concierge.VenuePage: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_initSearchCalendar : function() {
		this.debug("_initSearchCalendar");
		var self = this;

		if (this._is_dining_class || this._is_nightlife_class) {

			Pmp.Utils.LocalizeDatePicker(self._locale, "#search-date-input", "#search-date-input-submit");
			var sel = '#search-date-input';
			$(sel).datepicker('option', 'minDate', this._muni_today_date);

			if (this._initDateText !== undefined) {
				this.debug('Setting default date to today');
				$(sel).datepicker('option', 'defaultDate', this._initDateText);
				$(sel).datepicker('setDate', this._initDateText);
			}

			$(sel).datepicker('option', 'dayNamesMin', ['S','M','T','W','T','F','S']);
			$(sel).datepicker('option', 'monthNames', ['JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER']);
			$(sel).datepicker('option', 'showOtherMonths', true);
			$(sel).datepicker('option', 'selectOtherMonths', true);
			$(sel).datepicker('option', 'onSelect', function(dateText, calendar) {
				self._changeSearchDate();
			});

			$(sel).datepicker('option', 'beforeShowDay', function(date) {
				return self._beforeShowDay(date);
			});

			$('#search-date-input').addClass('calendar');

		}

	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;

		if (this._is_dining_class || this._is_nightlife_class) {
			Pmp.Utils.GoldButtonClickSubmit('#select-btn');
			Pmp.Utils.AddDropdown('#select-btn', '#contextual-content-select-btn');
		}

		var clickTabFn = function(event) {self._clickTab(event, $(this));}
		var onClickRadioBtn = function(event) {self.onClickRadioBtn(event);};

		$('.tab').click(clickTabFn);
		$('a.table-entry').click(onClickRadioBtn);

		Pmp.Utils.GoldButtonClickSubmit('#override-book-btn');

		$('#override-booking-datepicker').datepicker({
			dateFormat: "mm/dd/yy",
			showOtherMonths : true,
			selectOtherMonths : true
		});
		$('#ui-datepicker-div').addClass('calendar'); /* Theminng */
	},

	_initRotator : function() {
		var self = this;
		$('.rotator').cycle({
			fx: 'fade',
			prev : '.click-prev',
			next: '.click-next',
			pager: '.rotator-nav',
			onPagerEvent : function(zeroBasedSlideIndex, slideElement){
				self._onRotatorChange(zeroBasedSlideIndex);
			},
			onPrevNextEvent: function(isNext, zeroBasedSlideIndex, slideElement){
				self._onRotatorChange(zeroBasedSlideIndex);
			},
			timeout: 0 /* Disable auto advance */
		});
		$('.rotator-nav a').each(function(i, element){
			$(element).html('<img src="'+ self._photo_thumb_urls[i] + '" />');
		});
		$('.rotator-nav').find('img').batchImageLoad({
			loadingCompleteCallback: function() {
				self.debug('Thumbnails loaded.')
				self._onRotatorChange(0, 0);
			}
		});
	},

	_initDropdowns : function() {
		var self = this;

		if (this._is_nightlife_class || this._is_dining_class) {
            // Note: SearchDate is already wired up since it's a fake DK dropdown
            var changeSearchPartySizeFn = function(event) {self._changeSearchPartySize();};
            var clickSearchDateDisplayRowFn = function(event) {self._clickSearchDisplayRow($(this), 'date');};
            var clickSearchPartysizeDisplayRowFn = function(event) {self._clickSearchDisplayRow($(this), 'partysize');};
            var clickSearchDatePromptRowFn = function(event) {self._clickSearchPromptRow($(this), 'date');};
            var clickSearchPartysizePromptRowFn = function(event) {self._clickSearchPromptRow($(this), 'partysize');};

            $('#home-partysize-select').dropkick({'change':changeSearchPartySizeFn});

            $('#date-field-display').click(clickSearchDateDisplayRowFn);
            $('#partysize-field-display').click(clickSearchPartysizeDisplayRowFn);
            $('#date-prompt').click(clickSearchDatePromptRowFn);
            $('#partysize-prompt').click(clickSearchPartysizePromptRowFn);
		}

		if (this._is_dining_class) {
            var changeSearchTime = function(event) {self._changeSearchTime();};
            var clickSearchTimeDisplayRowFn = function(event) {self._clickSearchDisplayRow($(this), 'time');};
            var clickSearchTimePromptRowFn = function(event) {self._clickSearchPromptRow($(this), 'time');};

            $('#home-time-select').dropkick({'change':changeSearchTime});
            $('#time-field-display').click(clickSearchTimeDisplayRowFn);
            $('#time-prompt').click(clickSearchTimePromptRowFn);
		}

	},

	_initSearchPrompts : function() {

		if (this._is_dining_class) {
			return;
		}

		if (this._has_results) {
			this._changeSearchDate(true /*skipAnimation*/);
			this._changeSearchPartySize(true /*skipAnimation*/);
			//if (this._is_dining_class) {
			//	this._changeSearchTime(true /*skipAnimationAndSearch*/);
			//}
			$('#bookoptions-prompt').show();
			$('#search-banner').show();
			$('#search-results').show();
			$('a.table-entry:first').click();
		} else {
			$('.search-prompt').removeClass('no-display');
			$('#search-date-field').removeClass('no-display');
			$('#bookoptions-prompt').addClass('no-display');
			$('#search-banner').show();

			// TODO: this hack sucks but calendar has a bug that requires a date to be selected
			if (this._initDateText === undefined) {
				$('a.ui-state-active', '#search-date-input').removeClass('ui-state-highlight')
															.removeClass('ui-state-active')
															.removeClass('ui-state-hover');
			}
		}
	},

	_changeSearchDate : function(skipAnimation) {
		this.debug("_changeSearchDate");
		if (this._is_searching) {
			return;
		}
		var dateDisplay = this._getSelectedDateDisplay();

		var displayField = $('#date-field-display');
		displayField.find('.display-value').sext(dateDisplay);
		displayField.show();
		$('#date-prompt').hide();

		if (skipAnimation) {
			$('#search-date-field').hide();
			$('#search-partysize-field').show();
		} else {
			var self = this;
			$('#search-date-field').slideUp(this._search_prompt_fade_duration_ms, function() {
				if (self._has_results) {
					// Short-circuit and just search
					self._doSearch();
					return;
				}
				$('#search-partysize-field').slideDown(this._search_prompt_fade_duration_ms);
			});
		}
	},

	_changeSearchPartySize : function(skipAnimation) {
		this.debug("_changeSearchPartySize");
		var self = this;
		if (this._is_searching) {
			return;
		}
		var party_size_display = $('#home-partysize-select option:selected').sext();
		if (party_size_display === "- select -") { // Prompt option
			return;
		}

		if (reset_next_field && this._is_dining_class) {
			$('#search-partysize-field').slideUp(this._search_prompt_fade_duration_ms, function() {
				if (self._has_results) {
					// just do the search
					console.log('SLIDING UP TIMESLOT')
					$('#search-time-field').slideUp(this._search_prompt_fade_duration_ms);
					self._doSearch();
					return;
				}
			});
		}

		var displayField = $('#partysize-field-display');
		displayField.find('.display-value').sext(party_size_display);
		displayField.show();
		displayField.find('.expand-img').show();
		$('#partysize-prompt').hide();

		if (this._is_nightlife_class) {
            if (skipAnimation) {
                $('#search-partysize-field').hide();
            } else {
                // Hide results if just changed party size and still need to pick the ratio
                $('#bookoptions-prompt').hide();
                $('#search-results').hide();
                $('#search-partysize-field').slideUp(this._search_prompt_fade_duration_ms);
            }
		}

		if (this._is_dining_class) {
			$('#time-field-display').hide();
            $('#time-prompt').show();
            if (skipAnimation) {
                $('#search-partysize-field').hide();
                $('#search-time-field').show();
            } else {
                // Hide results if just changed party size and still need to pick the time slot
                $('#bookoptions-prompt').hide();
                $('#search-results').hide();
                $('#search-partysize-field').slideUp(this._search_prompt_fade_duration_ms, function() {
                	if (!self._has_results) {
                		$('#search-time-field').slideDown(this._search_prompt_fade_duration_ms);
                	}
                });
            }
		}

	},

	_changeSearchTime : function(skipAnimationAndSearch) {
        this.debug("_changeSearchTime");
        if (this._is_searching) {
            return;
        }
        var time_val = $('#home-time-select option:selected').sext();
        if (time_val === "- select -") { // Prompt option
            return;
        }

        var displayField = $('#time-field-display');
        displayField.find('.display-value').sext(time_val);
        displayField.show();
        displayField.find('.expand-img').show();
        $('#time-prompt').hide();
        if (skipAnimationAndSearch) {
            $('#search-time-field').hide();
        } else {
            $('#search-time-field').slideUp(this._search_prompt_fade_duration_ms);
            this._doSearch();
        }
    },

	_doSearch : function() {
		// Clean up any expanded search fields
		$('#search-results').hide();
		$(".search-field").hide();
		$(".search-prompt").hide();
		$(".search-field-display").show();

		this._is_searching = true;
		$('#searching-indicator').show(0, function() {
			// Delay so UI updates
			$('#search-form').submit();
		});

	},

	_clickSearchDisplayRow : function(element, field_key) {
		if (this._is_searching) {
			return;
		}
		$(element).hide();
		$('#'+ field_key +'-prompt').show();
		$('#'+ field_key +'-prompt').addClass('collapsible');
		$('#search-'+ field_key +'-field').slideDown(this._search_prompt_fade_duration_ms);
	},

	_clickSearchPromptRow : function(element, field_key) {
		if (this._is_searching) {
			return;
		}
		if ($(element).hasClass('collapsible') === false) {
			return;
		}
		$(element).hide();
		$('#'+ field_key +'-field-display').show();
		$('#search-'+ field_key +'-field').slideUp(this._search_prompt_fade_duration_ms);
	},

	_getSelectedDateDisplay : function() {
		if ((this._locale === 'en_US') || (this._locale === 'en_CA')) {
			return $.datepicker.formatDate('DD, MM d', $('#search-date-input').datepicker('getDate'));
		}
		return $.datepicker.formatDate('DD, d MM', $('#search-date-input').datepicker('getDate'));
	},

	_beforeShowDay : function(date) {
		var date_formatted = $.datepicker.formatDate('mm/dd/y', date);
		var day_of_week_short = $.datepicker.formatDate('D', date);
		var dow_index = $.inArray(day_of_week_short, ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']);
		var td_class = '';

		/* Trumping order of background colors:
		 *  - Passed Date
		 *  - Today
		 *  - Closed Date (except custom opened ones)
		 *  - Blackout Dates
		 */
		if (date.compareTo(this._muni_today_date_date) < 0) {
			td_class = 'passed-date';
		} else if (date.compareTo(this._muni_today_date_date) == 0) {
			td_class = 'today-date'
		} else if (!this._venueDaysOfOperation[dow_index] && $.inArray(date_formatted, this._venueCustomOpenedDates) < 0) {
			td_class = 'closed-date';
		} else if ($.inArray(date_formatted, this._venueBlackedDates)  >= 0) {
			td_class = 'closed-date';
		}

		return [true, td_class];
	},

	_onRotatorChange : function(index, duration_override) {
		var all_images = $('.rotator-nav img');
		if (all_images.length === 0) {
			/* Then only one photo */
			$('.rotator-nav-container').hide();
			return;
		}
		var container = $('.rotator-nav-container').offset();
		var next_image = all_images[index];
		var img_offset = $(next_image).offset();
		var new_offset_top = img_offset.top - container.top;
		var new_offset_left = img_offset.left - container.left;

		if (duration_override === undefined) {
			duration_override = this._gold_border_animation_duration_ms;
		}

		$('#rotator-gold-border').animate({
			top : new_offset_top,
			left: new_offset_left
		}, duration_override, function() {
			/* If first time, we need to show it*/
			$(this).show();
		});
	},

	_clickTab : function(event, element) {
		$('.tab.selected').removeClass('selected');
		$(element).addClass('selected');

		var content_div_id = $(element).attr('id') + '-content';
		$('.tab-content').hide();
		$('#' + content_div_id).show();
	},

	resetAllTableForms : function() {
	    $('input.entry-radio').attr('checked', false);
	    $('a.table-entry').removeClass('selected');
	    $('#select-btn').addClass('disabled');
		$('.contextual-content-select-btn').css('z-index', '50'); // Make all visible again
	},

	onClickRadioBtn : function(event) {
		this.resetAllTableForms();
		var anchor = Pmp.Utils.GetParentElemForEventHandle(event, 'a.table-entry');

	    // make sure the radio button is selected
	    anchor.find('input.entry-radio').attr('checked', 'checked');

	    // add selected class
	    anchor.addClass('selected');

	    // activate select button
	    var selectBtn = $('#select-btn');
	    selectBtn.removeClass('disabled');
	    selectBtn.closest('.venue-tables').find('.contextual-content-select-btn').css('z-index', '-9999'); // Hide it
	}
};
