var BookingBlocksService = (function () {

    var _getBlocks = function (url_key, date_param, shift_persistent_id) {
        var deferred = new $.Deferred();
        $.ajax({
            url: '/manager/' + url_key + '/blocks/list/date/' + date_param,
            data: {
                'shift_persistent_id': shift_persistent_id
            },
            success: function (resp) {
                deferred.resolve(resp);
            }
        });
        return deferred;
    };

    return {
        getBlocks: _getBlocks
    };
})();
