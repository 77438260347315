var ActivityLogService = (function() {
  var filterRequestUrl = '/api-yoa' + window.location.pathname + '/json';

  var getLogData = function(params) {
    return $.ajax({
      method: 'GET',
      url: filterRequestUrl,
      data: params,
      error: function(err) {
        Interface._alert('Something went wrong. Failed to retrieve data.');
      }
    });
  };

  return {
    getLogData: getLogData
  };

})();