var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Nightlife = Pmp.Concierge.Nightlife || {};

Pmp.Concierge.Nightlife.Search = {
    initialize : function(base_url,  muni_today_date, date_formatted_default, num_results, locale, media_url) {
        this._base_url = base_url;
        this._num_results = num_results;
        this._muni_today_date = muni_today_date;
        this._date_formatted_default = date_formatted_default;
        this._locale = locale;
        this._loaded_search_params = $('#search-form').serialize();
        this._media_url = media_url;
        this._neighborhood_filters = {};
        this._category_filters = {};
        this._atmosphere_filters = {};
        this._cuisine_filters = {};

        Pmp.Concierge.Common.SearchPage.initializeTagFilters(this);
        this.initFilters();

        this.bindEventHandlers();
        Pmp.Concierge.Request.initialize(this._base_url, this._muni_today_date, locale, "NIGHTLIFE");
        Pmp.Concierge.Common.SearchPage.initCommonFilterDropdowns(this, $(document));

        // init placeholders for IE8
        $('#search-query-input').placeholderLabel();
        return this;
    },

    debug : function(msg) {
        if (Pmp.Settings.DEBUG) {
            Pmp.Concierge.Common.Utils.debug("Pmp.Concierge.Nightlife.Search", msg);
        }
    },

    initFilters : function () {
        Pmp.Concierge.Common.SearchPage.initSingleFilter('#filter-neighborhood', 5);
        Pmp.Concierge.Common.SearchPage.initSingleFilter('#filter-atmosphere', 7);
        Pmp.Concierge.Common.SearchPage.initSingleFilter('#filter-category', 5);
    },

    bindEventHandlers : function() {
        var self = this;
        Pmp.Concierge.Common.SearchPage.bindCommonSearchPageEventHandlers(this);
        Pmp.Concierge.Common.SearchPage.bindDateHandlers(this);
        var changePartySize = function(event) { self._changePartySize(event); }
        $('#home-partysize-select').change(changePartySize);
    },

    _changePartySize : function(event) {
        this.debug("_changePartySize");
    }
};
