var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Common = Pmp.Concierge.Common || {};

Pmp.Concierge.Common.Utils = {
    log : function(msg) {
        console.log(msg);
    },

    debug : function(env, msg) {
        if (Pmp.Settings.DEBUG) {
            this.log([env, msg].join(": "));
        }
    },

    capitalize: function(str) {
        return str.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
    },
}

Pmp.Concierge.Common.SearchPage = {
    debug : function(msg) {
        Pmp.Concierge.Common.Utils.debug("Pmp.Concierge.Common.SearchPage", msg);
    },

    bindCommonSearchPageEventHandlers : function(entity) {
        var self = this;
        var $searchSubmit = $('#search-submit');
        var $document = $(document);
        var showListViewFn = function(event) {self._clickListView();};
        var showGridViewFn = function(event) {self._clickGridView();};
        $('#list-view-link').click(showListViewFn);
        $('#grid-view-link').click(showGridViewFn);

        var clickVenuePerksFn = function(event) {self._clickVenuePerks(event, this, entity);};
        var clickVenueRewardsFn = function(event) {self._clickVenueRewards(event, this, entity);};

        // don't double link
        $document.off('click', '.venue-perk');
        $document.off('click', '.venue-rewards');
        $document.off('click', '.venue-link');
        $document.on('click', '.venue-perk', clickVenuePerksFn);
        $document.on('click', '.venue-rewards', clickVenueRewardsFn);

        $searchSubmit.click(function() {
            $('#overlay').height($('#results').height());
            $('#overlay-container').removeClass('no-display');
            $('#top-panel div.matches').sext('');
            $("#search-form").submit(); 
        });
        $('#search-query-input').keypress(function(e) {
            if(e.which === 13) {
                $searchSubmit.click();
                return false;
            }
        });
    },

    bindDateHandlers : function (entity) {
        var self = this;
        Pmp.Utils.LocalizeDatePicker(entity._locale, "#search-date-input", "#search-date-input-submit");
        $('#search-date-input').datepicker('option', 'minDate', entity._muni_today_date);
        $('#ui-datepicker-div').addClass('calendar'); /* Theming */

        var clickDateDropdownFn = function(event) {self._clickDateDropdown(event);}
        var changeDateFn = function(event) {self._changeDate(entity._locale);}

        /* action handlers */
        $('#search-date-select').click(clickDateDropdownFn);
        $('#search-date-input').change(changeDateFn);
    },

    /*
     * Binds subscription handler across dining/nightlife/directory views
     *  Pmp.Concierge.Nightlife.Search
     *  Pmp.Concierge.Dining.Search
     */
    bindSubscriptionEvents : function (concierge_id) {
        $('.venue-subscription').off('click').on('click', function (e) {
            e.stopPropagation();
            var target = $(e.currentTarget),
                $target = $(target),
                targetDataId = $target.attr('data-id'),
                alreadySubscribed = $target.hasClass('subscribed'),
                data = {
                    'subscribe': !alreadySubscribed,
                    'venue_url_key': targetDataId,
                    'venue_id': targetDataId
                };
            $.ajax({
                type: 'POST',
                url: '/concierge/' + concierge_id + '/manage/user/subscribe',
                data: data,
                success: function (response) {
                    $target.tooltip('disable');
                    var targetUnsubTitle = $target.attr('data-unsub-title');
                    var targetSubTitle = $target.attr('data-sub-title');
                    $target.toggleClass('subscribed', !alreadySubscribed);
                    if (alreadySubscribed) {
                        $target.attr('title', targetUnsubTitle);
                        $target.tooltip('option', 'content', targetUnsubTitle);
                    } else {
                        $target.attr('title', targetSubTitle);
                        $target.tooltip('option', 'content', targetSubTitle);
                    }
                    $target.tooltip('enable');
                }
            });
        });
    },

    _clickVenuePerks : function(event, el, entity) {
        var venue_id = $(el).parents('.result-box').attr('venue_id');
        var url = entity._base_url + '/venue/'+ venue_id +'/perks?date=' + entity._date_formatted_default;
        Pmp.Main.Popup.Base.loadUrl(url, true);
        Pmp.Main.Popup.Base.showPopup(500);
    },

    _clickVenueRewards : function(event, el, entity) {
        Pmp.Main.Popup.Base.loadHtml("<div style='width:540px;height:181px'><img src='" + entity._media_url + "images/conciergerewards.png' /></div>");
        Pmp.Main.Popup.Base.showPopup(605);
    },

    _clickGridView : function() {
        $('#dv-input').val('g');
        this._showGridView();
    },

    _clickListView : function() {
        $('#dv-input').val('l');
        this._showListView();
    },

    _resetViews : function() {
        $('#grid-view-results').addClass('no-display');
        $('#list-view-results').addClass('no-display');
        $('#grid-view-link').removeClass('active');
        $('#list-view-link').removeClass('active');
    },

    _showGridView : function() {
        this._resetViews();
        $('#grid-view-results').removeClass('no-display');
        $('#grid-view-link').addClass('active');
        $('#dv-input').val('g');
    },

    _showListView : function() {
        this._resetViews();
        $('#list-view-results').removeClass('no-display');
        $('#list-view-link').addClass('active');
        $('#dv-input').val('l');
    },

    initializeTagFilters : function (entity) {
        $('.result-box').each(function(idx, el) {
            var i = 0;
            var $el = $(el);
            var nid = $el.attr('neighborhood_id');
            entity._neighborhood_filters[nid] = false;

            var tagList = $el.attr("atmosphere_keywords");
            if (tagList) {
                var atmosphereKeywords = tagList.split(',');
                for (var i=0; i<atmosphereKeywords.length; i++) {
                    entity._atmosphere_filters[$.trim(atmosphereKeywords[i])] = false;
                }
            }

            tagList = $el.attr("category_keywords");
            if (tagList) {
                var categoryKeywords = tagList.split(',');
                for (i=0; i<categoryKeywords.length; i++) {
                    entity._category_filters[$.trim(categoryKeywords[i])] = false;
                }
            }

            tagList = $el.attr("cuisine_keywords");
            if (tagList) {
                var cuisineKeywords = tagList.split(','); 
                for (i=0; i<cuisineKeywords.length; i++) {
                    entity._cuisine_filters[$.trim(cuisineKeywords[i])] = false;
                }
            }
        });
    },

    initSingleFilter : function(category_id, max) {
        var shouldShowMore = false;
        var $categoryFilterItem = $('.filter-item', category_id);
        $categoryFilterItem.each(function(idx, el) {
            if (idx >= max) {
                $(el).addClass('no-display');
                shouldShowMore = true;
            }
        });
        if (shouldShowMore) {
            var moreObj = $('.filter-item-more', category_id);
            moreObj.removeClass('no-display');
            moreObj.click(function() {
                var $this = $(this);
                if ($this.hasClass('nowhide')) {
                    $categoryFilterItem.addClass('no-display');
                    $categoryFilterItem.slice(0,max).removeClass('no-display');
                    $this.removeClass('nowhide');
                    $this.sext('More');
                } else {
                    $categoryFilterItem.removeClass('no-display');
                    $this.addClass('nowhide');
                    $this.sext('Less');
                }
            });
        }
    },

    _clickDateDropdown : function(event) {
        var $searchDateInput = $('#search-date-input')
        if ($('#ui-datepicker-div').is(':visible')) {
            $searchDateInput.datepicker('hide');
        } else {
            $searchDateInput.datepicker('show');
        }
    },

    _changeDate : function(entity_locale) {
        var dateFormat = (entity_locale === 'en_US' || entity_locale === 'en_CA') ? 'D, MM d' : 'D, d MM';
        var datestr = $.datepicker.formatDate(dateFormat, $('#search-date-input').datepicker('getDate'));
        $('#search-date-display').sext(datestr);
    },

    _changeVenueTypeFilter : function(entity, event, el) {
        var $el = $(el);
        var selectedVal = $.trim($el.parents('.filter-item').find('.filter-item-val').val());
        var isChecked = $el.is(':checked');
        entity._venue_type_filters[selectedVal] = isChecked;
        this._changeFilter(entity);
    },

    _changeNeighborhoodFilter : function(entity, event, el) {
        var $el = $(el);
        var selectedNid = $el.parents('.filter-item').find('.filter-item-val').val();
        var isChecked = $el.is(':checked');
        entity._neighborhood_filters[selectedNid] = isChecked;
        this._changeFilter(entity);
    },

    _changeKeywordFilter : function(entity, thedict, el) {
        var $el = $(el);
        var selectedVal = $.trim($el.parents('.filter-item').find('.filter-item-val').val());
        var isChecked = $el.is(':checked');
        thedict[selectedVal] = isChecked;
        this._changeFilter(entity);
      },

    initCommonFilterDropdowns : function(entity, doc) {
        var self = this;

        var changeVenueTypeFilterFn = function(event) {self._changeVenueTypeFilter(entity, event, this);}
        doc.on('change', '#filter-venue-type input:checkbox', changeVenueTypeFilterFn);

        var changeNeighborhoodFilterFn = function(event) {self._changeNeighborhoodFilter(entity, event, this);}
        doc.on('change', '#filter-neighborhood input:checkbox', changeNeighborhoodFilterFn);

        var changeCuisineFilterFn = function(event) {self._changeKeywordFilter(entity, entity._cuisine_filters, this);}
        doc.on('change', '#filter-cuisine input:checkbox', changeCuisineFilterFn);

        var changeAtmosphereFilterFn = function(event) {self._changeKeywordFilter(entity, entity._atmosphere_filters, this);}
        doc.on('change', '#filter-atmosphere input:checkbox', changeAtmosphereFilterFn);

        if (entity._category_filters) {
            var changeCategoryFilterFn = function(event) {self._changeKeywordFilter(entity, entity._category_filters, this);}
            doc.on('change', '#filter-category input:checkbox', changeCategoryFilterFn);
        }
    },

    _gdcChecked : function() {
        return $('input:checked','.gdc_label').length > 0;
    },

    _instantBookingChecked : function () {
        return $('input:checked','.instant_book_label').length > 0;
    },

    _neighborhoodsChecked : function () {
        return $('input:checked','#filter-neighborhood').length > 0;
    },

    _cuisineKeywordsChecked : function () {
        return $('input:checked','#filter-cuisine').length > 0;
    },

    _atmosphereKeywordsChecked : function () {
        return $('input:checked','#filter-atmosphere').length > 0;
    },
    
    _categoriesChecked : function () {
        return $('input:checked','#filter-category').length > 0;
    },

    _checkIfMeetsTagFilter : function (usesFilter, entityDict, elmt, tagIdentifier) {
        if (!usesFilter || !entityDict) {
            return true;
        }
        var tagList = elmt.attr(tagIdentifier);
        if (tagList) {
            keywords = tagList.split(',');
            for (var i=0; i<keywords.length; i++) {
                if (entityDict[$.trim(keywords[i])]) {
                    return true;
                }
            }
        }
        return false;
    },

    _changeFilter : function(entity) {
        var self = this;
        var $resultBox = $('.result-box')

        var filteringGdcVenues = self._gdcChecked();
        var filteringInstantBookVenues = self._instantBookingChecked();
        var filteringNeighborhoods = self._neighborhoodsChecked();
        var filteringCuisines = self._cuisineKeywordsChecked();
        var filteringAtmospheres = self._atmosphereKeywordsChecked();
        var filteringCategories = self._categoriesChecked();

        if (!filteringGdcVenues && !filteringInstantBookVenues && !filteringNeighborhoods && !filteringCuisines && !filteringAtmospheres) {
            $resultBox.removeClass('no-display');
            return;
        }

        $resultBox.addClass('no-display');
        $resultBox.each(function(i, el) {
            var $el = $(el);
            var isGdcVenue = $el.attr('is_gdc_venue');
            var isInstantBook = $el.attr('is_instant_book');
            var nid = $el.attr('neighborhood_id');

            var meetsGdcFilter = !filteringGdcVenues || entity._venue_type_filters['is_gdc_venue'].toString() === isGdcVenue;
            var meetsInstantBookFilter = !filteringInstantBookVenues || entity._venue_type_filters['is_instant_book'].toString() === isInstantBook;
            var meetsNeighborhoodFilter = !filteringNeighborhoods || entity._neighborhood_filters[nid];
            var meetsCuisineFilter = self._checkIfMeetsTagFilter(filteringCuisines,
                                                                 entity._cuisine_filters,
                                                                 $el,
                                                                 "cuisine_keywords");
            var meetsAtmosphereFilter = self._checkIfMeetsTagFilter(filteringAtmospheres,
                                                                    entity._atmosphere_filters,
                                                                    $el,
                                                                    "atmosphere_keywords");
            var meetsCategoryFilter = self._checkIfMeetsTagFilter(filteringCategories,
                                                                  entity._category_filters,
                                                                  $el,
                                                                  "category_keywords");

            shouldShow = (meetsNeighborhoodFilter && meetsCuisineFilter
                          && meetsAtmosphereFilter && meetsGdcFilter
                          && meetsInstantBookFilter && meetsCategoryFilter);

            if (shouldShow) {
                $el.removeClass('no-display');
            }
        });
    },
}