


var OfferService = {
    _getByVenueCachedResults : null,
    getByVenue : function(venueId, excludedOfferTypes, onSuccess, onError, canUseCached) {
      if (canUseCached && this._getByVenueCachedResults) {
        onSuccess(this._getByVenueCachedResults);
        return;
      }
      return $.ajax({
        url: '/api-yoa/experiences?venue='+venueId+'&excluded_offer_types='+excludedOfferTypes,
        global: false,
        success: function(response) {
          if (onSuccess !== undefined) {
            OfferService._getByVenueCachedResults = response.payload;
            onSuccess(response.data.results);
          }
        },
        error : onError
      });
    }
};
