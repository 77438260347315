var Pmp = Pmp || {};
Pmp.Admin = Pmp.Admin || {};

Pmp.Admin.NeighborhoodsService = {
  url: '/admin/neighborhood/list_api',
  entity_label: 'Neighborhoods',
  label_field: 'formatted_name',
  neighborhoods: null,
  fetch: function (callback) {
    if (this.neighborhoods) {
      setTimeout(function () {
        callback(this.neighborhoods);
      }.bind(this), 0);
      return;
    }
    Pmp.Client.LoadAjaxData(this.url, null, false, function (data) {
      if (!this._is_valid_response(data)) {
        callback(null);
        return;
      }
      this.neighborhoods = data.payload.content.entities;
      callback(this.neighborhoods);
    }.bind(this));
  },
  _is_valid_response: function (data) {
    return !!(data && data.payload && data.payload.content && data.payload.content.entities);
  },
};
