
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};

Pmp.Concierge.Request = {
	initialize : function(base_url, muni_today_date, locale, venue_class) {
		this._base = Pmp.Main.Popup.Base;
		this._base_url = base_url;
		this._muni_today_date = muni_today_date;
		this._initDateText = muni_today_date;
		this._locale = locale;
		this._today_date = Pmp.Utils.parseDate(muni_today_date, this._locale);
		this._is_dining = venue_class == 'DINING';
		this._is_nightlife = venue_class == 'NIGHTLIFE';
		this.bindPopupClick();

		this._blackedDates = [];
		this._customOpenedDates = [];
		this._daysOfOperation = [1,1,1,1,1,1,1];
		this._arrival_options = [];

		this._firstName = '';
		this._lastName = '';
		this._phoneNumber = '';

		this.overrideDate = null;
		this.overrideGuests = null;

		this.slider = null;

		var self = this;
		var destroyerFn = function() { self.destroy(); };
		Pmp.Client.Static.Factory.Register(Nightloop.Templates.Concierge.Request.CreatePopup,
		                                    null, destroyerFn);
	},

	log : function(msg) {
		console.log("Pmp.Concierge.Request: "+ msg);
	},
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	destroy : function() {
		this.debug("destroying");
		$(document).unbind('mouseup');
	},

	bindPopupClick : function() {
		var self = this;
		var popupLink = $('.custom-reservation-popup-link');
		popupLink.off();
		var clickCustomReservationLinkFn = function(event) {
			self._clickCustomReservationLink(event, this);
		};
		$(document).on('click', '.custom-reservation-popup-link', clickCustomReservationLinkFn);
	},

	initializeData : function (
										venueId,
										blackedDates,
										customOpenedDates,
										daysOfOperation,
										venue_class,
										min_arrival_order,
										max_arrival_order,
										interval,
										start_of_day_hour
									) {

		this._venueId = venueId;
	  this._blackedDates = blackedDates;
	  this._customOpenedDates = customOpenedDates;
	  this._daysOfOperation = daysOfOperation;
	  this._is_dining = venue_class == 'DINING';
	  this._is_nightlife = venue_class == 'NIGHTLIFE';
		if (this.slider) {
			this.slider.destroy();
		}
		this.slider = null;
	  this._arrival_options = [];
	  var step = interval /  15;
	  for (var i = min_arrival_order; i <= max_arrival_order; i += step) {
	    this._arrival_options.push(timeutils.sort_order_to_time(i, undefined, start_of_day_hour));
	  }

	  if (this._is_dining) {
	    this._hideNightlifeFields();
	  } else {
	    this._hideDiningFields();
	  }
	  $.colorbox.resize();

	},

	initializeClient : function (firstName, lastName, phoneNumber) {
		this._firstName = firstName;
		this._lastName = lastName;
		this._phoneNumber = phoneNumber;
	},

	setClientData : function () {
		$('#first_name').val(this._firstName);
		$('#last_name').val(this._lastName);
		$('#phone').val(this._phoneNumber);
	},

	setDefaultParams : function(date, max_guests, mf_ratio_male, mf_ratio_female) {
		if (date != null) {
			var dateObj = new Date(date);
			var date_formatted = $.datepicker.formatDate('mm/dd/y', dateObj);
			var day_of_week_short = $.datepicker.formatDate('D', dateObj);
			var dow_index = $.inArray(day_of_week_short, ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']);
			var disabled = false;
			if ($.inArray(date_formatted, this._blackedDates)  >= 0) {
				disabled = true;
			} else if (!this._daysOfOperation[dow_index] && $.inArray(date_formatted, this._customOpenedDates) < 0) {
				disabled = true;
			} else if (dateObj.compareTo(this._today_date) < 0) {
				disabled = true;
			}
			if (!disabled) {
				$('#monthly-calendar').datepicker('setDate', dateObj);
				this._onSelectDate(date);
			}
		};
		var boxInputSet = function (val, max, cls, otherid) {
			if ((val != "null") && (val != null) && (val != undefined)) {
				if (val <= max) {
					var found = false;
					$(cls).each(function() {
						if ($(this).attr('hiddenval') == val.toString()) {
							$(this).click();
							found = true;
							return false;
						}
					});
					if (!found) {
						$(otherid).val(val);
						$(otherid).change();
					}
				} else {
					$(otherid).val(val);
					$(otherid).change();
				}
			}
		};
		boxInputSet(max_guests, 10, '.max-guests', '#max-guests-other-input');
		boxInputSet(mf_ratio_male, 5, '.males', '#males-other-input');
		boxInputSet(mf_ratio_female, 5, '.females', '#females-other-input');
	},

	bindClickHandlers : function() {
		this.debug("Binding click handlers");
		var self = this;

		// handling dates
		this._initCalendar();
		$('#date-other-btn').click(function(){ $('#full-calendar-container').removeClass('no-display');});
		var onClickDocumentHideCalendar = function(event) {self._hideCalendarOnClick(event); };
		$(document).mouseup(onClickDocumentHideCalendar);
		this.bindBoxInput('.date', '#date-input', function() {
		  $('#date-other-btn').removeClass('selected');
		  $('#date-other-btn').sext('other');
			self._loadTimeOptions();
		});

		// for nightlife venues
		this.bindBoxInput('.males', '#males-input', function () {
			$('#males-other-input').val("");
			$('#males-other-input').removeClass('selected');
		});
		this.bindBoxInput('.females', '#females-input', function () {
			$('#females-other-input').val("");
			$('#females-other-input').removeClass('selected');
		});
		$('#males-other-input').change(function() {self._otherInputChanged(this, '#males-input', '.males');});
		$('#males-other-input').keyup(function() {self._otherInputChanged(this, '#males-input', '.males');});
		$('#females-other-input').change(function() {self._otherInputChanged(this, '#females-input', '.females');});
		$('#females-other-input').keyup(function() {self._otherInputChanged(this, '#females-input', '.females');});

		// for dining venues
		this.bindBoxInput('.max-guests', '#max-guests-input', function () {
			$('#max-guests-other-input').val("");
			$('#max-guests-other-input').removeClass('selected');
		});
		$('#max-guests-other-input').change(function() {self._otherInputChanged(this, '#max-guests-input', '.max-guests');});
		$('#max-guests-other-input').keyup(function() {self._otherInputChanged(this, '#max-guests-input', '.max-guests');});

		this._setupTimeSlider();

		Pmp.Utils.GoldButtonClickSubmit('#request-submit-btn', undefined, function (form, btn, event) {
		  self.clickSubmit(self, form, btn, event);
		});

	},

	_setupTimeSlider : function () {
		var self = this;
		if (self._is_dining) {
			if (self.slider) {
				self.slider.update({values:self._arrival_options})
			} else {
				$("#time-range-slider").ionRangeSlider({
			    type:"double",
			    values: self._arrival_options,
			    grid: true,
					force_edges: true,
					drag_interval: true,
			    hide_min_max: true
			  });
				self.slider = $("#time-range-slider").data("ionRangeSlider");
			}
		} else {
		  var select = $('select[name="arrival_time"]');
		  select.empty();
		  select.append($('<option/>').sext('').val(''));
		  for (var i = 0; i < self._arrival_options.length; ++i) {
		    select.append($('<option/>').sext(self._arrival_options[i]).val(self._arrival_options[i]));
		  }
		}
	},

	_hideDiningFields : function () {
		$('.dining-field').hide();
		$('.nightlife-field').show();
	},

	_hideNightlifeFields : function () {
		$('.dining-field').show();
		$('.nightlife-field').hide();
	},

	_hideCalendarOnClick : function(e) {
		var container = $('#date-row');
	    if (container.has(e.target).length === 0) {
	    	$("#full-calendar-container").addClass('no-display');
	    }
	},

	_initCalendar : function() {
		this.debug("initCalendar");
		var self = this;

		Pmp.Utils.LocalizeDatePicker(self._locale, '#monthly-calendar', '#date-input');
		var sel = '#monthly-calendar';
		$(sel).datepicker('option', 'minDate', '0D');
		$(sel).datepicker('option', 'defaultDate', this._initDateText);
		$(sel).datepicker('setDate', this._initDateText);
		$(sel).datepicker('option', 'dayNamesMin', ['S','M','T','W','T','F','S']);
		$(sel).datepicker('option', 'monthNames', ['JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER']);
		$(sel).datepicker('option', 'showOtherMonths', true);
		$(sel).datepicker('option', 'selectOtherMonths', true);
		$(sel).datepicker('option', 'onSelect',function(dateText, calendar) {
			self._onSelectDate(dateText, calendar);
			self._loadTimeOptions();
		});
		$(sel).datepicker('option', 'beforeShowDay', function(date) {
			return self._beforeShowDay(date);
		});
	},

	_onSelectDate : function(dateText, calendar) {
		this.debug("_onSelectDate");
		if (this._initDateText !== dateText) {
			var dateObj = $('#monthly-calendar').datepicker('getDate');
			var dateText = $.datepicker.formatDate('mm-dd-yy', dateObj);
			var updateText = $.datepicker.formatDate('mm/dd', dateObj);
			$('#date-other-btn').sext(updateText);
			$('#date-other-btn').addClass('selected');
			$("#full-calendar-container").addClass('no-display');
			$('.date').removeClass('selected');

		}
	},

	_loadTimeOptions : function () {
		var date = $('#date-input').val().replace(/\//g,'-');
		var self = this;
    $.ajax({
      url: '/venue/' + self._venueId + '/time_options/' + date,
      method: 'get',
      success: function(resp) {
        self._arrival_options = [];
			  for (var i = resp.payload.min_sort_order; i <= resp.payload.max_sort_order; i += 1) {
			    self._arrival_options.push(timeutils.sort_order_to_time(i, undefined, resp.payload.start_of_day_hour));
			  }
				self._setupTimeSlider();
      }
    });
	},

	_beforeShowDay : function(date) {

		var date_formatted = $.datepicker.formatDate('mm/dd/y', date);
		var day_of_week_short = $.datepicker.formatDate('D', date);
		var dow_index = $.inArray(day_of_week_short, ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']);
		var td_class = '';

		/* Trumping order of background colors:
		 *  - Blacked-out
		 *  - Today
		 *  - Closed Date (except custom opened ones)
		 *  - Passed Date
		 */
		if ($.inArray(date_formatted, this._blackedDates)  >= 0) {
			td_class = 'blacked-date ui-datepicker-unselectable ui-state-disabled';
		} else if (date.compareTo(this._today_date) == 0) {
			td_class = 'today-date'
		} else if (!this._daysOfOperation[dow_index] && $.inArray(date_formatted, this._customOpenedDates) < 0) {
			td_class = 'closed-date ui-datepicker-unselectable ui-state-disabled';
		} else if (date.compareTo(this._today_date) < 0) {
			td_class = 'passed-date ui-datepicker-unselectable ui-state-disabled';
		}

		return [true, td_class];
	},

	_otherInputChanged : function(obj, input, options){
		var num = $(obj).val();
		if (num.length > 0) {
			$(input).val(num);
			$(options).removeClass('selected');
			$(obj).addClass('selected')
		} else {
			$(options).first().click();
			$(obj).removeClass('selected');
		}
	},

	bindBoxInput : function (optionClass, hiddenInputId, postClickFn) {
		$(optionClass).click(function() {
			$(optionClass).removeClass('selected');
			$(this).addClass('selected');
			$(hiddenInputId).val($(this).attr('hiddenVal'));
			if (postClickFn != undefined) {
				postClickFn();
			}
		});
		$(optionClass).each(function() {
			if ($(this).hasClass('default')) {
				$(this).click();
				return false;
			}
		});
	},

	clickSubmit : function (self, form, btn, event) {
		if (self.validate()) {
			var formObj = $(form);
			Pmp.Utils.LoadForm(event, formObj, '#main-popup', Pmp.Main.Popup.Base.waitForLoadAndResize);
			return true;
		} else {
			$('#request-submit-btn').removeClass('pending disabled');
		}
	},

	isValidPhonenumber : function(value) {
	    return (/^\d{7,}$/).test(value.replace(/[\s()+\-\.]|ext/gi, ''));
	},

	isValidEmail : function(value) {
		// Validate email format - ported from Django's EmailValidator
	    var email_re_str =
	    	"(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*" + // dot-atom
		    '|^"([\\001-\\010\\013\\014\\016-\\037!#-\\[\\]-\\177]|\\\\[\\001-011\\013\\014\\016-\\177])*"' + // quoted-string
		    ')@(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+[A-Z]{2,6}\\.?$'; // domain
		 // Ignore-case
	    var email_re = new RegExp(email_re_str, "i");
	    return email_re.test(value);
	},

	validate : function () {
		var error = $('#error-msg');
		error.sext('');
		var first_name = $('#first_name').val();
		var last_name = $('#last_name').val();
		var phone = $('#phone').val();
		var email = $('#email').val();
		if (first_name.length <= 0) {
			$(error).sext('Please enter a first name');
			return false;
		}
		if ($('#phone').length && ((phone.length <= 0) || (!this.isValidPhonenumber(phone)))) {
			$(error).sext('Please enter a valid phone number');
			return false;
		}
		if ($('#email').length && email.length > 0) {
			if (!this.isValidEmail(email)) {
				$(error).sext('Please enter a valid email address');
				return false;
			}
		}
    if (this._is_nightlife && $('#males-input').val() == "0" && $('#females-input').val() == "0") {
      $(error).sext('Please enter number of males and females');
      return false;
    }
		return true;
	},

	_decodeSpecialCharacters : function(text) {
	    return text.replace('&amp;', '&');
    },

	_clickCustomReservationLink : function(event, element) {

		var isUndefined = function (val) {
			return ((val == '') || (val =='undefined') || (val == undefined) || (val==null));
		};

	    if( $(element).hasClass('nightlife') ) {
	    	$('#colorbox').removeClass('hide-gender');
	    } else {
	    	$('#colorbox').addClass('hide-gender');
	    }

    	this._current_venue = $(element).attr('venue_name');
    	this._current_venue_id = $(element).attr('venue_id');
    	this._current_date = $('#search-date-input').val();
			if (isUndefined(this._current_date)) {
				this._current_date = this.overrideDate;
			}
    	this._current_partysize = $('#home-partysize-select').val();
			if (isUndefined(this._current_partysize)) {
				this._current_partysize = this.overrideGuests;
			}
		var url = this._base_url + '/request/create/' + this._current_venue_id + '?';
		if (!isUndefined(this._current_date)) {
			url = url + 'date=' + this._current_date.split('/').join('-') + '&';
		}
		if (!isUndefined(this._current_partysize)) {
			url = url + 'max_guests=' + this._current_partysize + '&';
		}
    this._base.loadUrl(url, true);

		var self = this;
		this._base.showPopup(780, undefined, undefined); //width
	}
};
