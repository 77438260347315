var ClientsService = (function() {

  var _exportClientProfile = function(managerBaseUrl, profileId) {
    var deferred = new $.Deferred();
    var url = managerBaseUrl + '/clients/profile/' + profileId + '/export';
    $.ajax({
      url: url,
      method: 'post',
      success: function(resp) {
        deferred.resolve(resp.payload);
      },
      error : function(resp) {
        var error_json = $.parseJSON(resp.responseText);
        deferred.reject(error_json.message);
      }
    });
    return deferred;
  };

  var _removePersonalData = function(managerBaseUrl, profileId, removeNotes) {
    var deferred = new $.Deferred();
    var url = managerBaseUrl + '/clients/profile/' + profileId + '/remove_personal_data';
    $.ajax({
      url: url,
      method: 'post',
      data: {
        remove_notes: removeNotes
      },
      success: function(resp) {
        deferred.resolve(resp.payload);
      },
      error : function(resp) {
        var error_json = $.parseJSON(resp.responseText);
        deferred.reject(error_json.message);
      }
    });
    return deferred;
  };

  return {
    exportClientProfile: _exportClientProfile,
    removePersonalData: _removePersonalData
  };

})();
