
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Dining = Pmp.Concierge.Dining || {};

Pmp.Concierge.Dining.Landing = {
	initialize : function(base_url, muni_today_date, locale) {
		this._base_url = base_url;
		this._muni_today_date = muni_today_date;
		this._locale = locale;

		this._initRotators();
		this._bindEventHandlers();
		this._initDropdowns();
	},
	
	log : function(msg) {
		console.log("Pmp.Concierge.Dining.Landing: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		
		Pmp.Utils.LocalizeDatePicker(self._locale, "#search-date-input", "#search-date-input-submit");
		$('#search-date-input').datepicker('option', 'minDate', self._muni_today_date);
		$('#ui-datepicker-div').addClass('calendar'); /* Theming */
		
		var clickDateDropdownFn = function(event) {self._clickDateDropdown(event);}
		var changeDateFn = function(event) {self._changeDate(event);}
		var clickVenueFn = function(event) {self._clickVenue(event, $(this));}
		var mouseEnterVenueFn = function(event) {self._mouseEnterVenue(event, $(this));}
		var mouseLeaveVenueFn = function(event) {self._mouseLeaveVenue(event, $(this));}

		$('#search-date-select').click(clickDateDropdownFn);
		$('#search-date-input').change(changeDateFn);

		$('.venue-header').click(clickVenueFn);
		$('.rotator').click(clickVenueFn);
		$('.venue-photos').mouseenter(mouseEnterVenueFn);
		$('.venue-photos').mouseleave(mouseLeaveVenueFn);
		
		Pmp.Utils.GoldButtonClickSubmit('.submit-button');
		Pmp.Utils.AddClickDropdown('.city-dropdown-container','.city-dropdown');
	},
	
	_initRotators : function() {
		var self = this;
		$('.rotator').each(function(i, el) {
			var idx = $(el).attr('id').split('-')[1];
			$(el).cycle({
				fx: 'fade',
				prev: '#nav-prev-'+idx,
				next: '#nav-next-'+idx,
				timeout: 0 /* Disable auto advance */
			});
		});
	},
	
	_initDropdowns : function() {
		var self = this;
		var changeNeighborhoodFilterFn = function(event) {self._changeNeighborhoodFilter(event);};
		$('#filter-neighborhood-select').dropkick({'change':changeNeighborhoodFilterFn});
		$('select.dk').dropkick();
		this._changeDate();
	},

	_clickDateDropdown : function(event) {
		if ($('#ui-datepicker-div').is(':visible')) {
			$('#search-date-input').datepicker('hide');
		} else {
			$('#search-date-input').datepicker('show');
		}
	},
	
	_changeDate : function(event) {
		var datestr = $.datepicker.formatDate('DD, MM d', $('#search-date-input').datepicker('getDate'));
		$('#search-date-select .dk_label').sext(datestr);
	},
	
	_changeNeighborhoodFilter : function(event) {
		this.debug('_changeNeighborhoodFilter');
		var selected_nid = $('#filter-neighborhood-select').val();
		if (selected_nid === '') {
			$('.venue-listing').removeClass('no-display');
			return;
		}
		$('.venue-listing').each(function(i, el) {
			if ($(el).attr('neighborhood_id') === selected_nid) {
				$(el).removeClass('no-display');
			} else {
				$(el).addClass('no-display');
			}
		});
	},
	
	_clickVenue : function(event, element) {
		var venue_id = $(element).closest('.venue-listing').attr('venue_id');
		var url = this._base_url + '/venue/' +  venue_id;
		this.debug('Navigating to url: ' + url);
		Pmp.Client.Static.Page.Navigate('#nightloop', url);
	},
	
	_mouseEnterVenue : function(event, element) {
		if ($(element).find('.rotator img').length > 1) {
			$(element).find('.rotator-nav').removeClass('no-display');
		}
	},
	
	_mouseLeaveVenue : function(event, element) {
		$(element).find('.rotator-nav').addClass('no-display');
	}
};