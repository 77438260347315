
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Reservation = Pmp.Concierge.Reservation || {};

Pmp.Concierge.Reservation.Custom = {
	initialize : function(base_url, muni_today_date, locale) {
		this._base = Pmp.Main.Popup.Base;
		this._base_url = base_url;
		this._muni_today_date = muni_today_date;
		this._locale = locale;
		this.bindPopupClick();
	},
	
	log : function(msg) {
		console.log("Pmp.Concierge.Reservation.Custom: "+ msg);
	},
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	bindPopupClick : function() {
		var self = this;
		var clickCustomReservationLinkFn = function(event) {self._clickCustomReservationLink(event, this);};
		$('.custom-reservation-popup-link').click(clickCustomReservationLinkFn);
	},
	
	bindClickHandlers : function() {
		this.debug("Binding click handlers");
		var self = this;
		var clickSubmitBtnFn = function(event) {self._clickSubmitBtn(event);};
		$('#custom-reservation-submit-btn').click(clickSubmitBtnFn);
		$(function() {
			Pmp.Utils.LocalizeDatePicker(self._locale, ".customres-datepicker", ".customres-datepicker-submit");
			$(".customres-datepicker").datepicker('option', 'minDate', self._muni_today_date);
			$('#ui-datepicker-div').addClass('calendar'); /* Theming */
		});
		
		if (this._current_venue) {
			var input_venue = $('#custom-reservation-popup-form .input-venue');
		    if (input_venue.val() == '') {
		    	input_venue.val(this._decodeSpecialCharacters(this._current_venue));
	        }
		    $('.venue-selected-insns').removeClass('no-display');
		    $('.no-venue-selected-insns').addClass('no-display');
	    } else {
		    $('.venue-selected-insns').addClass('no-display');
		    $('.no-venue-selected-insns').removeClass('no-display');
		}
	    
	    if (this._current_date) {
			var input_date = $('#custom-reservation-popup-form .input-date');
			var input_default = $('#custom-reservation-popup-form .customres-datepicker-submit');
	        if (input_date.val() == '') {
	        	input_date.val(this._current_date);
	        	input_default.val(this._current_date);
            }
        }
	    
	    if (this._current_partysize) {
			var input_partysize = $('#custom-reservation-popup-form .input-partysize');
	    	if (input_partysize.val() == '') {
	    		input_partysize.val(this._current_partysize);
	    	}
	    }

      Pmp.Utils.TextareaOverlayPrompt('.request-details-container', '.request-details-prompt');
	},
	
	_decodeSpecialCharacters : function(text) {
	    return text.replace('&amp;', '&');
    },
	
	_clickCustomReservationLink : function(event, element) {
    if( $(element).hasClass('nightlife') ) {
      $('#colorbox').removeClass('hide-gender');
    } else {
      $('#colorbox').addClass('hide-gender');
    }
	  this._current_venue = $(element).attr('venue_name');
	  this._current_date = $('#search-date-input').val();
	  this._current_partysize = $('#home-partysize-select').val();
		this._base.loadUrl(this._base_url + '/res/custom', true);
		this._base.showPopup(488); //width
	},
	
	_clickSubmitBtn : function(event) {
		var formObj = $('#custom-reservation-popup-form');
		Pmp.Utils.LoadForm(event, formObj, '#main-popup', this._base.waitForLoadAndResize);
	}
};
