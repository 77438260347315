
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Clients = Pmp.Concierge.Clients || {};

Pmp.Concierge.Clients.Landing = {
	initialize : function(base_url, locale, muni_today_date,
						timeOptions, guestOptions, can_custom_dining_request,
						selectedBookedById, mediaUrl, userEmail,
						startDateUrl, endDateUrl) {
		this._base_url = base_url;
		this._locale = locale;
		this._muni_today_date = muni_today_date
		this._timeOptions = timeOptions;
		this._guestOptions = guestOptions;
		this._changed_reservation = false;
		this._can_custom_dining_request = can_custom_dining_request;
		this._mediaUrl = mediaUrl;

		this._selectedBookedById = selectedBookedById;
		this._userEmail = userEmail;
		this._startDateUrl = startDateUrl;
		this._endDateUrl = endDateUrl;

		// these are set when a reservation is loaded
		this._reservation = null;
		this._venue_name = null;
		this._venue_id = null;

		this._currentCursor = null;
		this._currentPage = 0;
		this._loadedPages = {};
		this._searchGuid = null;
		this._loadPageInProgress = false;

		this._bindEventHandlers();
		this._loadMoreReservations(true);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			Pmp.Concierge.Common.Utils.debug("Pmp.Concierge.Clients.Landing", msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("bindClickHandlers");
		var page = Pmp.Client.Static.Page;
		var that = this;

		var changeShowCanceledCheckboxFn = function(event) {that._changeShowCanceledCheckbox(event);};
		$('#show-canceled-checkbox').change(changeShowCanceledCheckboxFn);
		page.AddLiveHandler('.close-colorbox-link', 'click', $.colorbox.close);

		// TODO: Move these live click handlers into their own JS classes
		var clickClientResRowFn = function(event) {that._clickClientResRow(event);};
		var clickEditClientResFn = function(event) {that._clickEditClientRes(event);};
		var clickCancelReservationFn = function(event) {that._clickCancelReservation(event);};
		var clickCancelResNevermindBtnFn = function(event) {that._clickCancelResNevermindBtn(event);};
		var clickCancelResConfirmBtnFn = function(event) {that._clickCancelResConfirmBtn(event);};
		var clickResendEmailConfirmationFn = function(event) {that._clickResendEmailConfirmation(event);};
		var clickResendEmailNevermindBtnFn = function(event) {that._clickResendEmailNevermindBtn(event);};
		var clickResendEmailConfirmBtnFn = function(event) {that._clickResendEmailConfirmBtn(event);};
		var clickModifyReservationFn = function(event) {that._clickModifyReservation(event);};

		page.AddLiveHandler('.res-row', 'click', clickClientResRowFn);
		page.AddLiveHandler('#edit-res-details-btn', 'click', clickEditClientResFn);
		page.AddLiveHandler('.cancel-reservation', 'click', clickCancelReservationFn);
		page.AddLiveHandler('#cancel-res-nevermind-btn', 'click', clickCancelResNevermindBtnFn);
		page.AddLiveHandler('#cancel-res-confirm-btn', 'click', clickCancelResConfirmBtnFn);
		page.AddLiveHandler('#resend-email-confirmation', 'click', clickResendEmailConfirmationFn);
		page.AddLiveHandler('#resend-email-nevermind-btn', 'click', clickResendEmailNevermindBtnFn);
		page.AddLiveHandler('#resend-email-confirm-btn', 'click', clickResendEmailConfirmBtnFn);
		page.AddLiveHandler('.modify-reservation', 'click', clickModifyReservationFn);

		var refreshPageFn = function () {
			that.refreshPage();
		}

		var $filterConcierge = $('#filter-concierge');
		var $filterBookedBy = $('#filter-bookedby');
		var $filterMarket = $('#filter-market');
		var $filterStatus = $('#filter-status');
		var $filterReservations = $('#search-reservations');
		var $filterByDatetype = $('#filter-by-datetype');
		$filterConcierge.change(refreshPageFn);
		$filterBookedBy.change(refreshPageFn);
		$filterMarket.change(refreshPageFn);
		$filterStatus.change(refreshPageFn);
		$filterByDatetype.change(refreshPageFn);
		$filterReservations.on('keyup', function(event) {
			if (event.which === 13) {
				event.preventDefault();
				that.refreshPage();
				return false;
			}
		});
		$filterConcierge.on('change', function () {
			that.loadBookedBy($(this).val());
		});
		$filterStatus.on('change', function() {
			var table = $('#client-reservations-table');
			if ($(this).val() === 'canceled') {
				table.addClass('cancel-filter');
			} else {
				table.removeClass('cancel-filter');
			}
		});
		var conciergeId = $filterConcierge.val();
		if (conciergeId) {
			that.loadBookedBy(conciergeId);
		} else {
			$filterBookedBy.attr('disabled', 'disabled');
			$filterBookedBy.parents('.form-element').addClass('disabled');
		}

		that._setupDateRangeCalendar();
		that._bindInfiniteScroll();
		that._bindExport();
	},

	_bindInfiniteScroll : function () {
		var that = this;
		$(window).unbind('scroll');
		$(document).ready(function() {
			var win = $(window);
			win.scroll(function() {
				if (!$('#client-reservations-table').length) {
					return;
				}
				if ($(document).height() - win.height() === win.scrollTop()) {
					that._loadMoreReservations();
				}
			});
		});
	},

	_bindExport : function () {
		var that = this;
		$('.export-button').on('click', function () {
			Pmp.Main.Popup.Base.loadHtml(
				Nightloop.Templates.Concierge.ConfirmExportPopup(
					{ user_email: that._userEmail }
				)
			);
			Pmp.Main.Popup.Base.showPopup();
		});
	},

	initConfirmExport : function () {
		var that = this;
		var $confirmExportBtn = $('#confirm-export-button');
		$confirmExportBtn.on('click', function () {
			var button = $(this).parents('.button');
			if (button.hasClass('disabled')) {
				return;
			}
			var startDate = $('#start-date-submit').val();
			var endDate = $('#end-date-submit').val();
			if (!startDate && !endDate) {
				var $exportInitError = $('#export-init-error');
				$exportInitError.sext("Please select a date range before exporting.");
				$exportInitError.removeClass("no-display");
				$confirmExportBtn.css("marginTop", "0px");
				return;
			}
			button.addClass('disabled');
			var query = $('#search-reservations').val();
			var bookedById = $('#filter-bookedby').val();
			var conciergeId = $('#filter-concierge').val();
			var clientConciergeId = $('#filter-market').val();
			var status = $('#filter-status').val();
			var sortByDatetype = $('#filter-by-datetype').val();

			$.ajax({
	      url: that._base_url + '/clients/reservations/export',
				method: 'post',
	      data: {
	        query: query,
	        start_date: startDate,
	        end_date: endDate,
	        booked_by_id: bookedById,
	        concierge_id: conciergeId,
	        client_concierge_id: clientConciergeId,
	        status: status,
	        sort_by_datetype: sortByDatetype,
	      },
				error : function () {
					button.removeClass('disabled');
				},
	      success: function(resp) {
	        button.removeClass('disabled');
					$('#export-prompt-text').hide();
					$('#export-processing-text').show();
					$('#export-button-container').hide();
					$.colorbox.resize();
	      }
	    });
		});
	},

	_loadMoreReservations: function (clearTable) {
		var that = this;
		if (that._currentCursor === null && !clearTable) {
			return;  // this means there are no more results left
		}

		if (that._loadPageInProgress) {
			return; // don't load the next page if you're in progress
		}

		var query = $('#search-reservations').val();
		var startDate = $('#start-date-submit').val();
		var endDate = $('#end-date-submit').val();
		var bookedById = $('#filter-bookedby').val();
		var conciergeId = $('#filter-concierge').val();
		var clientConciergeId = $('#filter-market').val();
		var status = $('#filter-status').val();
		var sortByDatetype = $('#filter-by-datetype').val();

		var table = $('#client-reservations-table');
		if (clearTable) {
			// starting a new search
			table.empty();
			that._searchHash = '' + query + Math.random();
			that._currentPage = 0;
			that._currentCursor = null;
			that._loadedPages = {};
		} else {
			that._loadPageInProgress = true;
		}

		var html = Nightloop.Templates.Concierge.LoadingRow({
			'MEDIA_URL': that._mediaUrl
		});
		table.append($(html));


		ConciergeReservationService.get(
			that._base_url,
			query,
			startDate,
			endDate,
			bookedById,
			sortByDatetype,
			conciergeId,
			clientConciergeId,
			status,
			that._currentCursor,
			that._searchHash + that._currentPage
		).done(function (reservations, nextCursor, searchHash) {

			// drop all stale requests
			if (clearTable && ((that._searchHash + that._currentPage) !== searchHash)) {
				that._loadPageInProgress = false;
				return;
			}

			// skip all pages already loaded
			if (that._loadedPages[that._searchHash + that._currentPage]) {
				that._loadPageInProgress = false;
				return;
			}

			that._currentCursor = nextCursor;
			that._loadedPages[that._searchHash + that._currentPage] = true;
			that._currentPage += 1;

			if (clearTable) {
				table.empty();
				if (!reservations.length) {
					var html = Nightloop.Templates.Concierge.EmptyRow();
					table.append($(html));
					table.addClass('empty');
					that._loadPageInProgress = false;
					return;
				}
			}

			$(table).find('tr.loading').remove();
			for (var i=0; i<reservations.length; i++) {
				var html = Nightloop.Templates.Concierge.ReservationRow({
					'reservation': reservations[i],
				});
				table.append($(html));
			}
			Pmp.Utils.AddHover('res-row');
			that._loadPageInProgress = false;
		});
	},

	_setupDateRangeCalendar : function () {
		var that = this;
		var options = {
			format: 'DD MMM YY'
		};
		var drp = $('#start-date-calendar').dateRangePicker(options);
		drp.bind('datepicker-change',function(event,obj) {
			var fromDate = $.datepicker.formatDate(
				Pmp.Utils.dateFormat('en_US'),
				obj.date1
			);
			var toDate = $.datepicker.formatDate(
				Pmp.Utils.dateFormat('en_US'),
				obj.date2
			);
			$('#start-date-submit').val(fromDate);
			$('#end-date-submit').val(toDate);
			that._loadMoreReservations(true);
			$('#start-date-calendar').data('dateRangePicker').close();
		});

		$('#start-date-calendar').on('keyup', function (e) {
			if ($.inArray(e.which, [8,13,46]) && ($.trim($(this).val()) === '')) {
				$('#start-date-submit').val('');
				$('#end-date-submit').val('');
				$('#start-date-calendar').data('dateRangePicker').clear();
				$('#start-date-calendar').data('dateRangePicker').resetMonthsView();
				that._loadMoreReservations(true);
			}
		})
	},

	_setupCalendar : function () {
		var that = this;
		Pmp.Utils.LocalizeDatePicker(that._locale, "#start-date-calendar", "#start-date-submit");
		$('#start-date-calendar').datepicker('option', 'minDate', '-60D');
		$('#start-date-calendar').datepicker('option', 'firstDay', 0);

		$('#ui-datepicker-div').addClass('calendar'); /* Theming */

		//var clickDateDropdownFn = function(event) {that._clickDateDropdown(event);};
		var changeDateFn = function(event) {that._changeDate(event);};
		//$('#start-date-calendar').click(clickDateDropdownFn);
		$('#start-date-calendar').change(changeDateFn);
	},

	_clickDateDropdown : function(event) {
		if ($('#ui-datepicker-div').is(':visible')) {
			$('#start-date-calendar').datepicker('hide');
		} else {
			$('#start-date-calendar').datepicker('show');
		}
	},

	_changeDate : function(event) {
		if (!$('#start-date-calendar').val()) {
			$('#start-date-submit').val('');
		}
		this.refreshPage();
	},

	refreshPage : function () {
		this._loadMoreReservations(true); // reset the page
	},

	loadBookedBy : function (conciergeId) {
		var that = this;
		var url = '/concierge/' + conciergeId + '/bookedbynames';
		$.ajax({
			url: url,
			type: 'get'
		})
		.done(function(response) {
			var names = response.payload.booked_by_names;
			var bbselect = $('#filter-bookedby');
			bbselect.empty();
			bbselect.append($('<option>').val('').html('Agent'));
			for (var i = 0; i < names.length; i++) {
				var opt = $('<option>').val(names[i].id).sext(names[i].name);
				if (that._selectedBookedById === names[i].id) {
					opt.attr('selected', 'selected');
				}
				bbselect.append(opt);
			}
			if (names.length) {
				bbselect.removeAttr('disabled');
				bbselect.parents('.form-element').removeClass('disabled');
			} else {
				bbselect.attr('disabled', 'disabled');
				bbselect.parents('.form-element').addClass('disabled');
			}
		});
	},

	changedReservation : function() {
		this._changed_reservation = true;
	},

	_changeShowCanceledCheckbox : function() {
		var table = $('#client-reservations-table');
		if ($('#show-canceled-checkbox').is(':checked')) {
			table.addClass('show-canceled');
		} else {
			table.removeClass('show-canceled');
		}
	},

	_clickEditClientRes : function() {
		var res_id_elem = $('#edit-res-details-btn');
		this.loadClientResAction(res_id_elem, 'edit', true);
	},

	_clickClientResRow : function(event) {
		this.debug('_clickClientResRow');
		var res_row = Pmp.Utils.GetParentElemForEventHandle(event, 'tr.res-row');
		this.loadClientResAction(res_row, 'view', true);

		var that = this;
		var onCompleteFnHook = false;
		var onClosedFnHook = function() {that._onCloseOverlay();};
		var page = Pmp.Client.Static.Page;
		$('#clients-landing-popup').html('');
		Pmp.Utils.CreateDynamicPopup(
			'#clients-landing-popup-parent',
			'#clients-landing-popup',
			page,
			550,
			onCompleteFnHook,
			onClosedFnHook
		);
	},

	_onCloseOverlay : function() {
		this.debug('onCloseOverlay');
		if (this._changed_reservation) {
			this.debug('Refreshing the page');
			Pmp.Client.Static.Page.Load('#nightloop');
		}
	},

	/* Cancel Reservation handlers */

	_clickModifyReservation : function () {
		var that = this;
		// get from reservation
		var initDate = that._reservation.date_formatted_calendar;
		var html = Nightloop.Templates.Concierge.Availability({
			time_options : that._timeOptions,
			time_slot : that._reservation.arrival_time_concierge_display,
			guests_options : that._guestOptions,
			guests : that._reservation.max_guests,
			date : initDate,
			venue_name : that._venue_name,
			venue_id : that._venue_id,
			can_custom_dining_request : that._can_custom_dining_request
		});
		Pmp.Main.Popup.Base.loadHtml(html);

		var venue_class = (that._reservation.is_dining) ? 'DINING' : 'NIGHTLIFE';
		$('#main-popup').removeClass('empty');
		Pmp.Main.Popup.Base.showPopup(undefined, function() {
			Pmp.Concierge.Availability.initialize(
				that._base_url,
				that._venue_id,
				that._venue_name,
				!that._reservation.is_dining,
				that._reservation.venue_today_date,
				initDate,
				[], //venuedaysofops
				[],// custom opened dates
				[], // blacked dates
				that._locale, //concierge locale
				that._timeOptions, // venue time options
				true,
				that._reservation.id,
				that._reservation.actual_id,
				that._can_custom_dining_request
			);
			Pmp.Concierge.Request.initialize(
				that._base_url,
				initDate,
				that._locale,
				venue_class
			);
			Pmp.Concierge.Request.initializeClient(
				that._reservation.first_name,
				that._reservation.last_name,
				that._reservation.phone_number_formatted
			);
			Pmp.Concierge.Availability.loadAvailability();

		});
	},

	_clickCancelReservation : function() {
		var anchor = $('#cancel-reservation');
		this.loadClientResAction(anchor, 'cancel', true);
	},

	_clickCancelResNevermindBtn : function(el) {
		var anchor = Pmp.Utils.GetParentElemForEventHandle(el, '#cancel-res-nevermind-btn');
		this.loadClientResAction(anchor, 'view', true);
	},

	_clickCancelResConfirmBtn : function(el) {
		this.changedReservation();
		var anchor = Pmp.Utils.GetParentElemForEventHandle(el, '#cancel-res-confirm-btn');
		this.loadClientResAction(anchor, 'cancel?confirm=true', true);
	},


	/* Resend Email handlers */
	_clickResendEmailConfirmation : function() {
		var anchor = $('#resend-email-confirmation');
		this.loadClientResAction(anchor, 'resend-email', true);
	},

	_clickResendEmailNevermindBtn : function(el) {
		var anchor = Pmp.Utils.GetParentElemForEventHandle(el, '#resend-email-nevermind-btn');
		this.loadClientResAction(anchor, 'view', true);
	},

	_clickResendEmailConfirmBtn : function(el) {
		var anchor = Pmp.Utils.GetParentElemForEventHandle(el, '#resend-email-confirm-btn');
		this.loadClientResAction(anchor, 'resend-email?confirm=true', true);
	},

	/* Supported actions: 'view', 'edit', 'cancel', 'resend-email' */
	loadClientResAction : function(res_id_elem, action, do_cbox_resize) {
		var that = this;
		var res_id = $(res_id_elem).attr('res_id');
		var url = this._base_url + '/clients/reservation/' + res_id + '/' + action;
		var onLoadFn = function() {};
		if (do_cbox_resize) {
			onLoadFn = function(payload) {
				that._reservation = payload.content.reservation;
				that._venue_name = payload.content.venue_name;
				that._venue_id = payload.content.venue_id;
				// Make sure all img tags are loaded before resizing to avoid scrollbar appearing
				$('#clients-landing-popup').find('img').batchImageLoad({
					loadingCompleteCallback: $.colorbox.resize
				});
			};
		}

		Pmp.Utils.LoadUrl(url, '#clients-landing-popup', onLoadFn);
	}
};
