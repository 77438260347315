


var BookedByService = {
    _getByVenueCachedResults : null,
    getByVenue : function(manager_base_url, onSuccess, onError, canUseCached) {
      if (canUseCached && this._getByVenueCachedResults) {
        onSuccess(this._getByVenueCachedResults);
        return;
      }
      return $.ajax({
        url: manager_base_url + '/data/bookedbynames',
        global: false,
        success: function(response) {
          if (onSuccess !== undefined) {
            BookedByService._getByVenueCachedResults = response.payload;
            onSuccess(response.payload);
          }
        },
        error : onError
      });
    }
};
