var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Manage = Pmp.Concierge.Manage || {};

Pmp.Concierge.Manage.ConciergePromotersEdit = {
	initialize : function(next_promoter_index) {
		// Leverage view page functionality
		Pmp.Concierge.Manage.ConciergePromotersView.initialize();
		this._next_promoter_index = next_promoter_index;
		this._bindEventHandlers();
	},
	
	log : function(msg) {
		console.log("Pmp.Concierge.Manage.ConciergePromotersEdit: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("bindEventHandlers");

		var self = this;
		var clickAddNewPromoterNameFn = function(event) {self._clickAddNewPromoterName(event);};
		var clickDeletePromoterNameFn = function(event) {self._clickDeletePromoterName(event);};
		var clickSubmitFn = function(event) {self._clickSubmit(event);};
		var keyupPromoterNameFn = function(event) {self._keyupPromoterName(event);};
		
		$('#add-new-promoter-name').click(clickAddNewPromoterNameFn);
		$('#conciergepromoters-submit-btn').click(clickSubmitFn);
		
		this.debug("Number of promoters: "+ this._next_promoter_index);
		for (var i=0; i < this._next_promoter_index; i++) {
			this._setInputOverlayPrompt(i);
		}
        var page = Pmp.Client.Static.Page;
		page.AddLiveHandler('.concierge-promoter-delete-link', 'click', clickDeletePromoterNameFn);
		page.AddLiveHandler('.promoter-name-input', 'keyup', keyupPromoterNameFn);
	},
	
	_clickAddNewPromoterName : function(event) {
	    var new_record = $($('#concierge-promoter-name-clone').html());
	    new_record.find('.input-container').attr('id', 'promoter-name-container-'+this._next_promoter_index);
	    new_record.find('.promoter-name-prompt').attr('id', 'promoter-name-prompt-'+this._next_promoter_index);
	    new_record.insertBefore($('#add-new-promoters-here'));
	    this._setInputOverlayPrompt(this._next_promoter_index);
	    this._next_promoter_index++;
	},
	
	_setInputOverlayPrompt : function(index) {
		Pmp.Utils.InputOverlayPrompt('#promoter-name-container-'+index, '#promoter-name-prompt-'+index);
	},
	
	_clickDeletePromoterName : function(event) {
		var link = $(event.target);
		var row = link.parents('.concierge-promoter-name');
		row.remove();
	},
	
	_clickSubmit : function(event) {
	    if (this._validate()) {
	        $('#conciergepromoters-edit-form').submit();
        }
	},
	
	_keyupPromoterName : function(event) {
		var input = $(event.target);
		input.removeClass('invalid');
	},
	
	_validate : function() {
		var isValid = true;
		
		$('.promoter-name-input').each(function() {
			if ($(this).parents('#concierge-promoter-name-clone').length !== 0) {
				return;
			}
			
			$(this).val($.trim($(this).val()));
			if ($(this).val() == '') {
				isValid = false;
				$(this).addClass('invalid');
			}
		});
		
		if (!isValid) {
			$('#validation-error-message').show().delay(4000).fadeOut(1000);
		}
		
		return isValid;
	}
};