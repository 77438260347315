

var MemberGroupService = {

    getByVenue : function(manager_base_url, include_deleted, onSuccess, onError) {
      var params;
      if (include_deleted) {
        params = '?include_deleted=1';
      } else {
        params = '';
      }

      return $.ajax({
        url: manager_base_url + '/membershipgroups' + params,
        global: false,
        success: function (groups) {
          if (onSuccess !== undefined) {
            onSuccess(groups);
          }
        },
        error : onError
      });
    },


    addToClient : function(manager_base_url, group_id, venue_group_client_id, plan_id, start_date, onSuccess, onError) {
      var data = {
    	  plan_id: plan_id,
    	  start_date: start_date
      };
      return $.ajax({
        url: manager_base_url + '/membershipgroups/' + group_id + '/client/' + venue_group_client_id,
        method: 'put',
        data: data,
        success: function (resp) {
          if (onSuccess !== undefined) {
            onSuccess(resp);
          }
        },
        error : function(resp) {
          var error_json = $.parseJSON(resp.responseText);
          if (onError !== undefined) {
            onError(error_json.errors);
          }
        }
      });

    },

    remove : function(manager_base_url, group_id, onSuccess, onError) {

      return $.ajax({
        url: manager_base_url + '/membershipgroups/' + group_id,
        method:'delete',
        success: function () {
          if (onSuccess !== undefined) {
            onSuccess();
          }
        },
        error : function(resp) {
          var error_json = $.parseJSON(resp.responseText);
          if (onError !== undefined) {
            onError(error_json.errors);
          }
        }
      });

    }

};
