var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Dining = Pmp.Concierge.Dining || {};

Pmp.Concierge.Dining.Search = {
    initialize : function(base_url, reservation_id, muni_today_date, date_formatted_default, locale, media_url) {
        this._base_url = base_url;
        this._reservation_id = reservation_id;
        this._muni_today_date = muni_today_date;
        this._date_formatted_default = date_formatted_default;
        this._locale = locale;
        this._media_url = media_url;
        this._venue_type_filters = {};
        this._neighborhood_filters = {};
        this._atmosphere_filters = {};
        this._cuisine_filters = {};
        this._category_filters = {};

        this.initFilters();
        this.bindEventHandlers();
        Pmp.Concierge.Request.initialize(this._base_url, this._muni_today_date, locale, "DINING");
        Pmp.Concierge.Common.SearchPage.initCommonFilterDropdowns(this, $(document));
        Pmp.Concierge.Common.SearchPage.initializeTagFilters(this);

        // init placeholders for IE8
        $('#search-query-input').placeholderLabel();
    },

    debug : function(msg) {
        Pmp.Concierge.Common.Utils.debug("Pmp.Concierge.Dining.Search", msg);
    },

    bindEventHandlers : function() {
        var self = this;
        Pmp.Concierge.Common.SearchPage.bindCommonSearchPageEventHandlers(this);
        Pmp.Concierge.Common.SearchPage.bindDateHandlers(this);
    },

    initFilters : function () {
        Pmp.Concierge.Common.SearchPage.initSingleFilter('#filter-neighborhood', 5);
        Pmp.Concierge.Common.SearchPage.initSingleFilter('#filter-cuisine', 5);
        Pmp.Concierge.Common.SearchPage.initSingleFilter('#filter-atmosphere', 7);
    },

    batchSearch : function(concierge_id, muni_id, date_str, party_size, time_slot, venue_ids, reservation_id) {
        var that = this;
        var chunk = 20;
        var batches = [];
        var venue_ids_array = venue_ids.split(',');
        for (var i = 0; i < venue_ids_array.length; i += chunk) {
            batches.push(venue_ids_array.slice(i, i + chunk));
        }
        var params = {
            concierge_id: concierge_id,
            municipality: muni_id,
            date: date_str,
            max_guests: party_size,
            time_slot: time_slot
        };
        var num_calls = batches.length;
        for (var i = 0; i < batches.length; ++i) {
            params['venue_ids'] = batches[i].join(',');
            $.ajax({
                url: this._base_url + '/dining/batchsearch?' + $.param(params),
                type: 'get'
            })
            .done(function(response) {
                if (response.statusCode === 200) {
                    var is_grid_view = $('#dv-input').val() === 'g';
                    if (is_grid_view) {
                        Pmp.Concierge.Common.SearchPage._clickGridView();
                    } else {
                        Pmp.Concierge.Common.SearchPage._clickListView();
                    }
                    var existing_list = $('#list-view-results').find('div.result-box.list-view');
                    var existing_grid = $('#grid-view-results').find('div.result-box');
                    var sort_cursor = 0;
                    var payload = response.payload;
                    var i, res, data, list, grid, el, matches, s;
                    var spinner_url = that._media_url + 'images/spinner.gif';
                    for (i = 0; i < payload.content.search_results.length; ++i) {
                        res = payload.content.search_results[i];
                        while (sort_cursor < existing_list.length) {
                            var current_score = parseInt($(existing_list[sort_cursor]).attr('sort_score'))
                            if (current_score > res.venue.sort_score) {
                                break;
                            }
                            ++sort_cursor;
                        }
                        data = {
                            'venue_photos': res.venue.photos,
                            'venue_name': res.venue.name,
                            'venue_neighborhood': res.venue.neighborhood.name,
                            'venue_blur': res.venue.detail_blurb,
                            'venue_id': res.venue.id,
                            'neighborhood_id': res.venue.neighborhood.id,
                            'has_perks': res.venue.has_perks,
                            'result': res,
                            'concierge': payload.concierge,
                            'reward': res.venue.rewards_display,
                            'max_guests': payload.content.form.fields.max_guests.value,
                            'date_formatted_default': payload.content.date_formatted_default,
                            'sort_score': res.venue.sort_score,
                            'subscribed': res.venue.subscribed,
                            'reservation_id': reservation_id,
                            'search_query': $('#search-form').serialize(),
                            'is_gdc_venue': res.venue.is_global_dining_collection,
                            'is_instant_book': res.venue.use_full_dining_backend,
                            'MEDIA_URL': that._media_url,
                        };
                        list = Nightloop.Templates.Concierge.Dining.VenueSearchResultList(data);
                        var grid = Nightloop.Templates.Concierge.Dining.VenueSearchResult(data);
                        if (sort_cursor === existing_list.length) {
                            $('#list-view-results').append(list);
                            $('#grid-view-results').append(grid);
                        } else {
                            $(list).insertBefore($(existing_list[sort_cursor]));
                            $(grid).insertBefore($(existing_grid[sort_cursor]));
                        }
                    }
                    Pmp.Concierge.Common.SearchPage.bindSubscriptionEvents(concierge_id);
                    $('.venue-subscription').tooltip({
                        hide: false,
                        position: {
                            my: 'center bottom',
                            at: 'center top-10',
                            'collision': 'none'
                        },
                        tooltipClass: 'venue-subscription-tooltip'
                    });
                    $el = $('#top-panel div.matches');
                    matches = existing_list.length + payload.content.search_results.length;
                    s = matches === 1 ? '' : 's';
                    $el.sext(matches + ' result' + s + ' found so far... ');
                    $el.append($('<img />').attr('src', spinner_url));
                }
            })
            .fail(function() {
                console.log('A batch search failed.')
            })
            .always(function() {
                --num_calls;
                if (num_calls === 0) {
                    var list_results = $('#list-view-results').find('div.result-box.list-view');
                    var matches = list_results.length;
                    var es = matches === 1 ? '' : 'es';
                    $('#top-panel div.matches').sext('Search completed. Found ' + matches + ' match' + es +'.');
                    var neighborhood_id_map = {}
                    var neighborhoods = {};
                    var neighborhood_sort_key = []
                    var cuisines = {};
                    var cuisine_sort_key = [];
                    var atmospheres = {};
                    var atmosphere_sort_key = [];
                    var num_gdc_venues = 0;
                    var num_instant_book_venues = 0;
                    for (var i = 0; i < matches; ++i) {
                        var $el = $(list_results[i]);
                        var is_gdc_venue = $el.attr('is_gdc_venue');
                        var is_instant_book_venue = $el.attr('is_instant_book');
                        if (is_gdc_venue === 'true') {
                            ++num_gdc_venues;
                        }
                        if (is_instant_book_venue === 'true') {
                            ++num_instant_book_venues;
                        }
                        var neighborhood_id = $el.attr('neighborhood_id');
                        var neighborhood_name = $el.attr('neighborhood_name');
                        var cuisine_keywords = $el.attr('cuisine_keywords');
                        var atmosphere_keywords = $el.attr('atmosphere_keywords');
                        neighborhood_id_map[neighborhood_name] = neighborhood_id;
                        if (neighborhoods[neighborhood_name]) {
                            ++neighborhoods[neighborhood_name];
                        } else {
                            neighborhoods[neighborhood_name] = 1;
                            neighborhood_sort_key.push(neighborhood_name);
                        }
                        var cuisine_list = cuisine_keywords.split(',');
                        for (var j = 0; j < cuisine_list.length; ++j) {
                            var c = Pmp.Concierge.Common.Utils.capitalize(cuisine_list[j]);
                            if (c) {
                                if (cuisines[c]) {
                                    ++cuisines[c];
                                } else {
                                    cuisines[c] = 1;
                                    cuisine_sort_key.push(c);
                                }
                            }
                        }
                        var atmosphere_list = atmosphere_keywords.split(',')
                        for (var j = 0; j < atmosphere_list.length; ++j) {
                            var a = Pmp.Concierge.Common.Utils.capitalize(atmosphere_list[j]);
                            if (a) {
                                if (atmospheres[a]) {
                                    ++atmospheres[a];
                                } else {
                                    atmospheres[a] = 1;
                                    atmosphere_sort_key.push(a);
                                }
                            }
                        }
                    }
                    var gdcLabel = $('.gdc_label');
                    if (gdcLabel) {
                        $(gdcLabel[0]).parent().next('.count').sext(num_gdc_venues);
                    }
                    var instantBookLabel = $('.instant_book_label');
                    if (instantBookLabel) {
                        $(instantBookLabel[0]).parent().next('.count').sext(num_instant_book_venues);
                    }
                    neighborhood_sort_key.sort();
                    cuisine_sort_key.sort();
                    atmosphere_sort_key.sort();
                    $('#filter-neighborhood .filter-item-container').empty();
                    for (var j = 0; j < neighborhood_sort_key.length; ++j) {
                        var name = neighborhood_sort_key[j];
                        var data = {
                            'name': name,
                            'checked': false,
                            'val': neighborhood_id_map[name],
                            'count': neighborhoods[name],
                        };
                        $('#filter-neighborhood .filter-item-container').append(Nightloop.Base.FilterItem(data));
                    }
                    $('#filter-cuisine .filter-item-container').empty();
                    for (var j = 0; j < cuisine_sort_key.length; ++j) {
                        var name = cuisine_sort_key[j];
                        var data = {
                            'name': name,
                            'checked': false,
                            'val': name,
                            'count': cuisines[name]
                        };
                        $('#filter-cuisine .filter-item-container').append(Nightloop.Base.FilterItem(data));
                    }
                    $('#filter-atmosphere .filter-item-container').empty();
                    for (var j = 0; j < atmosphere_sort_key.length; ++j) {
                        var name = atmosphere_sort_key[j];
                        var data = {
                            'name': name,
                            'checked': false,
                            'val': name,
                            'count': atmospheres[name]
                        };
                        $('#filter-atmosphere .filter-item-container').append(Nightloop.Base.FilterItem(data));
                    }
                    that.initFilters();
                }
            });
        }
    },
};