var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Venue = Pmp.Concierge.Venue || {};

Pmp.Concierge.Venue.Search = {
	initialize : function(base_url, concierge_id) {
		this._base_url = base_url;
		this._concierge_id = concierge_id;
		
		this._scope = '#nav-area';
		this._bindEventHandlers();
		this._do_search_obj = undefined;
		
		
		
		var self = this;
		var destroyerFn = function() { self.destroy(); };
		Pmp.Client.Static.Factory.Register(Nightloop.Templates.Concierge.Layout, 
                null, destroyerFn);
		
		this._refresh_count = 0;
		
		// init placeholders for IE8
		$('#concierge-venue-search-input', $(this._scope)).placeholderLabel();
		
		if (this._venue_data === undefined) { 
			this._fetch_venues(this, true);
		}
	},
	
	log : function(msg) {
		console.log("Pmp.Concierge.Venue.Search: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	destroy : function() {
		this.debug('destroying');
		this._clearSearch();
		$(document).unbind('mouseup');
	},
	
	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var venueSearchChangeFn = function(event) { self._venueSearchChange(event); };
		var venueSearchKeyupFn = function(event) { self._venueSearchKeyup(event); };
		var venueSearchFocusOutFn = function(event) { self._venueSearchFocusOut(event); };

		//$('#concierge-venue-search-input').on('change', venueSearchChangeFn);
		$('#concierge-venue-search-input').on('keyup', venueSearchKeyupFn);

		var page = Pmp.Client.Static.Page;
		page.AddLiveHandler(this._scope + ' .venue-search-result', 'click', function(event) { self._clickVenueSearchResult(event, this); });

		Pmp.Concierge.Common.SearchPage.bindSubscriptionEvents(this._concierge_id);
		$('.venue-subscription').tooltip({
			hide: false,
			position: {
				my: 'center bottom',
				at: 'center top-10',
				'collision': 'none'
			},
			tooltipClass: 'venue-subscription-tooltip'
		});

		var onClickDocumentHideDropdown = function(event) {self._hideDropdownOnClick(event); };
		$(document).mouseup(onClickDocumentHideDropdown);
	},
	
	_hideDropdownOnClick : function(e) {
		var container = $("#venue-search-results", $(this._scope));
	    if (container.has(e.target).length === 0)
	    {
	    	$('#venue-search-results', $(this._scope)).addClass('no-display');
	    	this._clearSearch();
	    	
	    }
	},
	
	_clickVenueSearchResult : function(event, el) {
    console.log(event);
    console.log(el);

		$(el).blur();
		this._clearSearch();
		var venue_key = $(el).find('.venue_key').html();
		Pmp.Client.Static.Page.Navigate('#nightloop', this._base_url+'/venue/'+venue_key);
		$('#venue-search-results', $(this._scope)).addClass('no-display');
	},
	
	_venueSearchChange : function(event) {
		this.debug('_venueSearchChange');
		this._setSearch();
	},
	
	_venueSearchKeyup : function(ev) {
		this.debug('_venueSearchKeyup');
		// detect enter, left, up, down, right
		if ((ev.which == 37) || (ev.which == 38)) {
      // left/up
			var selected = $('#venue-search-results .venue-search-result.selected', $(this._scope));
			var all_visible = $('#venue-search-results .venue-search-result', $(this._scope));
			var idx = all_visible.index(selected);
			if ((idx-1) >= 0) {
				var new_selected = all_visible.get(idx-1);
				if ($(new_selected).length > 0) {
					$(new_selected).addClass('selected');
					$(selected).removeClass('selected');
				}
			}
      ev.preventDefault();
    } else if ((ev.which == 39) || (ev.which == 40)){
     	// right/down
     	var selected = $('#venue-search-results .venue-search-result.selected', $(this._scope));
			var all_visible = $('#venue-search-results .venue-search-result', $(this._scope));
    	var idx = all_visible.index(selected);
    	if ((idx+1) < all_visible.length) {
    		var new_selected = all_visible.get(idx+1);
    		if ($(new_selected).length > 0) {
    			$(new_selected).addClass('selected');
    			$(selected).removeClass('selected');
    		}
    	}
      ev.preventDefault();
    } else if (ev.which == 13) {
      // enter
      var selected = $('#venue-search-results .venue-search-result.selected', $(this._scope));
      if (selected.length > 0) {
        $(ev.target).blur();
      	this._clickVenueSearchResult(null,selected);
     	}
      ev.preventDefault();
      return false;
    } else {
    	this._setSearch();
    }
	},
	
	_startAnimating : function() {
		$('#venue-search-spinner', $(this._scope)).removeClass('no-display');
	},
	
	_stopAnimating : function() {
		$('#venue-search-spinner', $(this._scope)).addClass('no-display');
	},
	
	_clearSearch : function() {
		if (this._do_search_obj !== undefined) {
			clearTimeout(this._do_search_obj);
			this._do_search_obj = undefined;
		}
	},
	
	_setSearch : function (){
		this._clearSearch();
		var self = this;
		var doSearch = function() {self._doSearch();};
		this._do_search_obj = setTimeout(doSearch, 200);
	},
	
	_doSearch : function () {
		this.debug('_doSearch for venue search called.')
		var self = this;
		this._startAnimating();
		this._clearSearch();
		var search_q = $('#concierge-venue-search-input', $(this._scope)).val().toLowerCase();

    console.log('***' + search_q + '***');

		if (search_q != '') {
			if (self._refresh_count % 50 == 0) {
				//self._fetch_venues(self);
			}
			if (self._venue_data != undefined) {
				self._handleLoadVenueSearchResults(self._venue_data, self);
			}
			self._refresh_count += 1;
		} else {
			this._stopAnimating();
			$('#venue-search-results', $(this._scope)).addClass('no-display');
		}
	},
	
	_fetch_venues : function(self, results_hide) {
		var onLoadFn = function(data) { 
			self._venue_data = data;
			if (results_hide === undefined) {
				self._handleLoadVenueSearchResults(self._venue_data, self);
			}
		};
		var url = self._base_url + '/venue/search'; // just get all the venues
		Pmp.Client.AsyncGet(url, onLoadFn);
	},
	
	_handleLoadVenueSearchResults : function(data, self) {
		this.debug('_handleLoadVenueSearchResults called')
		var venues = data.payload.content.venues;
		var html_block = '';
		var current_count = 0;
		
		var search_q = $('#concierge-venue-search-input', $(self._scope)).val().toLowerCase();
		
		for (var i=0; i<venues.length;i++) {
			
			var vname = venues[i].name;
			vname = vname.toLowerCase();
			if (vname.indexOf(search_q) === -1) {
				continue;
			}
			
			if (current_count == 20) {
				break;
			}
			
			var isFirst = (i == 0) ? 'selected' : '';
			var isLast = (i==venues.length-1) ? 'last' : '';
			var html = "<div class='venue-search-result "+ isFirst + " " + isLast + "'>" + venues[i].name + "<span class='venue_key no-display'>" + venues[i].url_key_or_id + "</span></div>";
			html_block += html;
			current_count += 1;
		}
		if(html_block=='') {
			var html_block = "<div class='venue-search-result'> No results found </div>";
		}
		$('#venue-search-results', $(self._scope)).html(html_block);
		$('#venue-search-results', $(self._scope)).removeClass('no-display');
		self._stopAnimating();
	}
};
