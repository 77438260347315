var AccessRulesService = (function () {

    var _getSingleDayRules = function (url_key, date_param, category) {
        var deferred = new $.Deferred();
        $.ajax({
            url: '/api-yoa/booking_access',
            data: {
                'venue': url_key,
                'start_date': date_param,
                'end_date': date_param,
                'shift_category': category
            },
            success: function (resp) {
                deferred.resolve(resp);
            }
        });
        return deferred;
    };

    return {
        getSingleDayRules: _getSingleDayRules
    };
})();
