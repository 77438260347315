
var Pmp = Pmp || {};
Pmp.Concierge = Pmp.Concierge || {};
Pmp.Concierge.Manage = Pmp.Concierge.Manage || {};
Pmp.Concierge.Manage.User = Pmp.Concierge.Manage.User || {};

Pmp.Concierge.Manage.User.Edit = {
	initialize : function(is_new_user) {
		this._is_new_user = is_new_user;
		this._is_in_validation_mode = false;
		
		this._bindEventHandlers();
		this.updatePrivilegeLevelOptions(false);
	},
	
	log : function(msg) {
		console.log("Pmp.Concierge.Manage.User.Edit: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		
		var self = this;
		var clickRemoveAccessBtnFn = function(event) {self._clickRemoveAccessBtn(event, $(this));}
		var clickCancelRemoveAccessBtnFn = function(event) {self._clickCancelRemoveAccessBtn(event);};
		var clickRemoveAccessConfirmBtnFn = function(event) {self._clickRemoveAccessConfirmBtn(event);};
		var changeSelectPrivilegeLevelFn = function(event) {self._changeSelectPrivilegeLevel(event, $(this));};
		var submitHandlerFn = function(formHandle) {self._submitHandler(formHandle); };
		var keyupValidateFieldFn = function(event) {self._validateForm(); };
		
		/* action handlers */
		$('#remove-user-access-btn').click(clickRemoveAccessBtnFn);
		$('.remove-user-access-cancel').click(clickCancelRemoveAccessBtnFn);
		$('#remove-user-access-confirm-btn').click(clickRemoveAccessConfirmBtnFn);
		$('#select-privilege-level').change(changeSelectPrivilegeLevelFn);
		$('.first-name').keyup(keyupValidateFieldFn);
		$('.last-name').keyup(keyupValidateFieldFn);
		$('.email').keyup(keyupValidateFieldFn);
		
		Pmp.Utils.GoldButtonClickSubmit('#save-user-access-edit-btn', undefined, submitHandlerFn);
		
		
	},
	
	_clickRemoveAccessBtn : function(event, element) {
		this.debug('_clickRemoveAccessBtn');
		$('#remove-user-access-confirm').removeClass('no-display');
	},
	
	_clickCancelRemoveAccessBtn : function(event) {
		this.debug('_clickCancelRemoveAccessBtn');
		$('#remove-user-access-confirm').addClass('no-display');
	},
	
	_clickRemoveAccessConfirmBtn : function(event) {
		$('#input-do-delete-concierge-user').val('true');
		$('#save-user-access-edit-btn').click();
	},
	
	updatePrivilegeLevelOptions : function(doResetOptions) {
		var newPrivLevel = $('#select-privilege-level').val();
		$('.addl-options').addClass('no-display');
		if (doResetOptions === true) {
			$('.addl-options input').prop('checked','');
		}
		$('.addl-options-'+newPrivLevel).removeClass('no-display');
		$('.privilege-box').addClass('no-display');
		$('.privilege-box-'+newPrivLevel).removeClass('no-display');
	},
	
	_changeSelectPrivilegeLevel : function(event, element) {
		this.updatePrivilegeLevelOptions(true);
	},
	
	_validateForm : function() {
		if (this._is_new_user === false) {
			return true;
		}
		if (this._is_in_validation_mode === false) {
			return;
		}
		var firstName = $('.first-name').val();
		var validFirstName = !Pmp.Common.Reservation.Validator.isEmpty(firstName);
		if (validFirstName === false) {
			$('#invalid-first-name').removeClass('no-display');
		} else {
			$('#invalid-first-name').addClass('no-display');
		}
		
		var lastName = $('.last-name').val();
		var validLastName = !Pmp.Common.Reservation.Validator.isEmpty(lastName);
		if (validLastName === false) {
			$('#invalid-last-name').removeClass('no-display');
		} else {
			$('#invalid-last-name').addClass('no-display');
		}
		
		var email = $('.email').val();
		var validEmail = Pmp.Common.Reservation.Validator.isValidEmail(email);
		if (validEmail === false) {
			$('#invalid-email').removeClass('no-display');
		} else {
			$('#invalid-email').addClass('no-display');
		}
		
		var isValid = validFirstName && validLastName && validEmail;
		
		if (isValid === false) {
			$('#invalid-all').removeClass('no-display');
		} else {
			$('#invalid-all').addClass('no-display');
		}
		
		return isValid;
	},
	
	_submitHandler : function(formHandle) {
		this._is_in_validation_mode = true;
		var isValid = this._validateForm(formHandle);
		if (isValid === true) {
			$(formHandle).submit();
		} else {
			$('#save-user-access-edit-btn').removeClass('pending disabled');
		}
	}
};