var BlackoutDateService = (function() {

  var _postBlackoutDate = function(managerBaseUrl, dateParam, blackoutDateType, blackoutAction) {
    var deferred = new $.Deferred();
    var url = managerBaseUrl + '/home/blackoutdate/' + dateParam + '/toggle';
    $.ajax({
      url: url,
      method: 'post',
      data: {
        blackoutDateType: blackoutDateType,
        blackoutAction: blackoutAction
      },
      success: function(resp) {
        deferred.resolve(resp.payload.is_enabled);
      },
      error : function(resp) {
        var error_json = $.parseJSON(resp.responseText);
        deferred.reject(error_json.message);
      }
    });
    return deferred;
  };

  var _blackoutDateReservation = function (managerBaseUrl, dateParam) {
    return _postBlackoutDate(managerBaseUrl, dateParam, 'RESERVATION', 'BLACKOUT');
  };

  var _unblackoutDateReservation = function (managerBaseUrl, dateParam) {
    return _postBlackoutDate(managerBaseUrl, dateParam, 'RESERVATION', 'UNBLACKOUT');
  };

  var _blackoutDateGuestlist = function (managerBaseUrl, dateParam) {
    return _postBlackoutDate(managerBaseUrl, dateParam, 'GUESTLIST', 'BLACKOUT');
  };

  var _unblackoutDateGuestlist = function (managerBaseUrl, dateParam) {
    return _postBlackoutDate(managerBaseUrl, dateParam, 'GUESTLIST', 'UNBLACKOUT');
  };

  return {
    blackoutDateReservation: _blackoutDateReservation,
    unblackoutDateReservation: _unblackoutDateReservation,
    blackoutDateGuestlist: _blackoutDateGuestlist,
    unblackoutDateGuestlist: _unblackoutDateGuestlist
  };

})();
